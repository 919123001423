// src/handlers/onMapClick.ts

import { IMapConfig } from "../interfaces/mapConfig";

interface OnMapClickContext {
  setSelectedCluster: (cluster: any) => void;
  setSelectedCorpusId: (id: any) => void;
  setSelectedClusterDetails: (details: any) => void;
}

const onMapClick = (event: any, context: OnMapClickContext, config: IMapConfig) => {
  const { setSelectedCluster, setSelectedCorpusId, setSelectedClusterDetails } = context;
  const feature = event.features && event.features[0];
  if (feature && feature.id) {
    const getFeatureType = (feature: any) => {
      const corpusLayers = [
        "author_papers",
        "author_citations", 
        "paper_references",
        "paper_citations",
        "author_references",
        "paper_labels",
        "papers"
      ];

      // Check if it's a cluster
      if (config.cluster_layer_prefixes?.some(prefix => 
        feature.sourceLayer?.startsWith(prefix)
      )) {
        return "cluster";
      }

      // Check if it's a corpus feature
      if (
        corpusLayers.includes(feature.sourceLayer) ||
        corpusLayers.includes(feature.source) ||
        feature.source?.startsWith("search_landmarks") ||
        feature.sourceLayer?.startsWith("highlight_")
      ) {
        return "corpus"; 
      }

      return null;
    };

    const featureType = getFeatureType(feature);
    console.log(feature.source, feature.sourceLayer, featureType);

    if (featureType === "cluster") {
      let bounds: any = null;
      if (
        "min_lng" in feature.properties &&
        "min_lat" in feature.properties &&
        "max_lng" in feature.properties &&
        "max_lat" in feature.properties
      ) {
        bounds = [
          feature.properties.min_lng,
          feature.properties.min_lat,
          feature.properties.max_lng,
          feature.properties.max_lat,
        ];
      }

      setSelectedCluster({
        cluster_id: feature.id,
        bounds: bounds,
      });
      setSelectedCorpusId(null);
    } else if (featureType === "corpus") {
      setSelectedCorpusId(feature.id);
      setSelectedCluster(null);
      setSelectedClusterDetails(null);
    }
  } else {
    setSelectedCluster(null);
    setSelectedClusterDetails(null);
    setSelectedCorpusId(null);
    // Assuming setSelectedPaperDetails exists in context
    // setSelectedPaperDetails(null);
  }
};

export default onMapClick;