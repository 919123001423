// PaperListItem.tsx
import "./PaperList.scss";
import { useContext } from "react";
import IPaperMeta from "../../interfaces/paperMeta";
import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import IAuthorMeta from "../../interfaces/authorMeta";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import { Typography, Box, Tooltip } from "@mui/material";
import AuthorsDisplay from "../paper/AuthorsDisplay";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import numberWithCommas from "../../utils";
import CitationsIcon from "../paper/CitationIcon";

interface AuthorsInlinedProps {
  authors: IAuthorMeta[] | undefined;
  showTooltip?: boolean;
}

function CitationCountDisplayInline({
  citationCount,
  color = "#b85900",
  showBorder = true,
}: {
  color?: string;
  showBorder?: boolean;
  citationCount: number;
}) {
  return (
    <div
      style={{
        borderRadius: 10,
        border: showBorder ? "0px solid #d9dadb" : "1px solid rgba(0,0,0,0.)",
        padding: showBorder ? "0px 8px" : 0,
        color: color, //#1890ff
        fontSize: 11,
      }}
    >
      <CitationsIcon color={color}></CitationsIcon>
      <span style={{ paddingLeft: 5 }}>
        {numberWithCommas(citationCount || 0) + " citations" ||
          "could not find citations"}
      </span>
    </div>
  );
}

const AuthorsInlined: React.FC<AuthorsInlinedProps> = ({ authors,showTooltip=false }) => {
  if (!authors || authors.length === 0) {
    return null;
  }

  const firstAuthor = authors[0];
  const remainingCount = authors.length - 1;
  const allAuthors = authors
    .map((author: IAuthorMeta) => author.name)
    .join(", ");

  return (
    showTooltip ? <Tooltip title={allAuthors} arrow>
      <Box display="flex" alignItems="center">
        <PeopleOutlinedIcon fontSize="small" style={{ marginRight: 4 }} />
        <Typography variant="caption" color="textSecondary">
          {firstAuthor.name}
          {remainingCount > 0 ? ` +${remainingCount}` : ""}
        </Typography>
      </Box>
    </Tooltip> : <Box display="flex" alignItems="center">
        <PeopleOutlinedIcon fontSize="small" style={{ marginRight: 4 }} />
        <Typography variant="caption" color="textSecondary">
          {firstAuthor.name}
          {remainingCount > 0 ? ` +${remainingCount}` : ""}
        </Typography>
      </Box>
  );
};

interface PaperListItemProps {
  paper: IPaperMeta;
  type: HoveredPaperType;
}

export default function PaperListItem({ paper, type }: PaperListItemProps) {
  const { setHoveredPaper, setSelectedCorpusId } = useContext(MapContext);

  const onClick = () => {
    setSelectedCorpusId(paper.id);
  };

  return (
    <div
      className="paperListItem"
      onMouseOver={() => {
        setHoveredPaper({
          source: "list",
          geometry: paper.geometry,
          title: paper.title,
          id: paper.id,
          type: type,
        });
      }}
      onMouseLeave={() => {
        setHoveredPaper(null);
      }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") onClick();
      }}
      aria-label={`Paper titled ${paper.title}`}
    >
      <Typography
        variant="subtitle2"
        component="div"
        className="paperTitle"
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left"
        }}
      >
        {paper.title}
      </Typography>
      <AuthorsInlined authors={paper.authors} />
      <Box display="flex" alignItems="center" gap={1}>
        <Typography
          variant="caption"
          color="textSecondary"
          className="paperYear"
        >
          Year: {paper.year}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          |
        </Typography>
        <CitationCountDisplayInline
          //color="rgba(0, 0, 0, 0.6)"
          citationCount={paper.citationcount}
        />
      </Box>
    </div>
  );
}
