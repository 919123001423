import React, { useState } from 'react';
import {
    TextField,
    Button,
    CircularProgress,
    Box,
    Typography,
    Paper,
    MenuItem,
    Select,
    FormControl,
    IconButton
} from '@mui/material';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { promptFunctionCalling_old } from '../../requests';

interface FunctionCallingPromptingProps {
    selection: any;
    contexts: any;
}

const defaultConfigs = [
    {
        label: "Summarize Cluster",
        config: {
            system_prompt: "You are given a list of paper titles from a cluster. Your goal is to extract and summarize the most relevant information",
            function_calling_args: {
                label: {
                    type: "string",
                    description: "A short label (max 3 words) which describes the cluster. The label should be concise enough to be used as a title for the cluster, while being as specific as possible (in the format 'X' or 'X&Y')."
                }
            },
            required_keys: ["label"],
            function_name: "summarize_cluster",
            function_description: "summarizes information from the given cluster data",
            message_prompt_prefix: "Summarize the cluster based on the following sampled paper titles\n"
        }
    },
    // Add more default configurations as needed
];

const FunctionCallingPrompting: React.FC<FunctionCallingPromptingProps> = ({ selection, contexts }) => {
    const snackbar = useSnackbar();
    const [input, setInput] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<any>(null);
    const [model, setModel] = useState<string>('gpt-4o-mini');
    const [configText, setConfigText] = useState<string>(JSON.stringify(defaultConfigs[0].config, null, 2));
    const [isConfigValid, setIsConfigValid] = useState<boolean>(true);

    const handleSend = async () => {
        setLoading(true);
        setResponse(null);

        try {
            const config = JSON.parse(configText);
            const responseDict = await promptFunctionCalling_old(selection, contexts, config, model);
            setResponse(responseDict);
        } catch (error) {
            snackbar.showSnackbar("Error sending message", "error");
        } finally {
            setLoading(false);
        }
    };

    const handleConfigChange = (event: any) => {
        const selectedConfig = defaultConfigs.find(conf => conf.label === event.target.value);
        if (selectedConfig) {
            setConfigText(JSON.stringify(selectedConfig.config, null, 2));
            setIsConfigValid(true);
        }
    };

    const handleConfigTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value;
        setConfigText(newText);
        try {
            JSON.parse(newText);
            setIsConfigValid(true);
        } catch {
            setIsConfigValid(false);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Box display="flex" alignItems="center" width="100%" gap={0}>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        defaultValue={defaultConfigs[0].label}
                        onChange={handleConfigChange}
                        renderValue={() => "Select Configuration"}
                    >
                        {defaultConfigs.map((conf) => (
                            <MenuItem key={conf.label} value={conf.label}>
                                {conf.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <TextField
                label="Edit Configuration JSON"
                value={configText}
                onChange={handleConfigTextChange}
                fullWidth
                multiline
                rows={10}
                error={!isConfigValid}
                helperText={!isConfigValid ? "Invalid JSON format" : ""}
                style={{ borderColor: !isConfigValid ? 'red' : '' }}
            />
            <FormControl variant="outlined" style={{ minWidth: 150 }}>
                <Select
                    value={model}
                    onChange={(e) => setModel(e.target.value as string)}
                    renderValue={() => model}
                >
                    <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
                    <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
                    <MenuItem value="gpt-4o">gpt-4o</MenuItem>
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSend} disabled={loading || !isConfigValid}>
                Send
            </Button>
            {loading && <CircularProgress />}
            {response && (
                <Paper elevation={3} style={{ padding: '10px', marginTop: '10px' }}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                        {JSON.stringify(response, null, 2)}
                    </Typography>
                </Paper>
            )}
        </Box>
    );
};

export default FunctionCallingPrompting;
