import { IconButton, Skeleton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { promptFullText, getCachedResponse } from "../../requests";
import { useSnackbar } from "../../contexts/SnackbarContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MarkdownEditor from "../utils/MarkdownEditor";
import IPaperSelection from "../../interfaces/paperSelection";

export default function AIAnswer({
  autoGenerate = false,
  autoGenerateDelay = 0,
  selection,
  contexts,
  answer,
  prompt,
  model = "gpt-4o-mini",
  allowRegenerate = false,
  markdownStyles = {},
  numSkeletonRows = 2,
  cachingKey = null,
}: {
  autoGenerate?: boolean;
  autoGenerateDelay?: number;
  selection: IPaperSelection;
  contexts: any;
  answer?: string | null;
  prompt: any;
  model?: string;
  allowRegenerate?: boolean;
  markdownStyles?: any;
  numSkeletonRows?: number;
  cachingKey?: string | null;
}) {
  const snackbar = useSnackbar();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoadingCache, setIsLoadingCache] = useState(false);
  const [isWaitingToGenerate, setIsWaitingToGenerate] = useState(false); // New state
  const [responseValue, setResponseValue] = useState<string | null>(answer!);
  const [hasError, setHasError] = useState(false);
  const hasAutoGenerated = useRef(false);
  const abortController = useRef<AbortController | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMounted = useRef(true); // Track if component is mounted

  useEffect(() => {
    // Set isMounted to true when component mounts
    isMounted.current = true;

    // Cleanup function
    return () => {
      // Set isMounted to false when component unmounts
      isMounted.current = false;

      // Clear any pending timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Abort any ongoing requests
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  const loadCachedAnswer = async (): Promise<boolean> => {
    if (!cachingKey) return false;

    if (!isMounted.current) return false; // Prevent actions if unmounted

    setIsLoadingCache(true);
    // Remove setHasError(false) here to prevent resetting error state on cache load
    abortController.current = new AbortController();

    try {
      const cached = await getCachedResponse(cachingKey, abortController.current.signal);

      if (!isMounted.current) return false; // Prevent state updates if unmounted

      if (cached && cached.response && cached.response.text) {
        setResponseValue(cached.response.text);
        return true;
      } else if (cached && cached.text) {
        setResponseValue(cached.text);
        return true;
      }

      return false;
    } catch (error) {
      if (!(error instanceof DOMException && error.name === 'AbortError')) {
        // Optionally log the error for debugging
        //console.error("Error loading cached response:", error);
        // Do not set hasError here
      }
      return false;
    } finally {
      if (isMounted.current) {
        setIsLoadingCache(false);
      }
    }
  };

  const generateAnswer = async () => {
    if (!isMounted.current) return; // Prevent action if unmounted

    setIsGenerating(true);
    setResponseValue(null);
    // Remove setHasError(false) here to prevent resetting error state before generation
    abortController.current = new AbortController();

    try {
      const response = await promptFullText(
        selection,
        contexts,
        prompt,
        model,
        cachingKey,
        abortController.current.signal
      );

      if (!isMounted.current) return; // Prevent state updates if unmounted

      setResponseValue(response.text);
    } catch (error) {
      if (!(error instanceof DOMException && error.name === 'AbortError')) {
        if (isMounted.current) {
          setHasError(true);
          snackbar.showSnackbar("Error sending message", "error");
        }
      }
    } finally {
      if (isMounted.current) {
        setIsGenerating(false);
      }
    }
  };

  useEffect(() => {
    const handleAutoGenerate = async () => {
      if (autoGenerate && !hasAutoGenerated.current) {
        let cacheLoaded = false;
        if (cachingKey) {
          cacheLoaded = await loadCachedAnswer();
        }

        if (!cacheLoaded) {
          hasAutoGenerated.current = true;
          if (autoGenerateDelay > 0) {
            setIsWaitingToGenerate(true); // Start waiting
            timeoutRef.current = setTimeout(() => {
              if (isMounted.current) {
                setIsWaitingToGenerate(false); // Stop waiting
                generateAnswer();
              }
            }, autoGenerateDelay);
          } else {
            generateAnswer();
          }
        }
      }
    };

    handleAutoGenerate();

    // Note: The cleanup is handled in the first useEffect
  }, [autoGenerate, selection, cachingKey, autoGenerateDelay]);

  return (
    <div className="aiAnswer">
      {responseValue && !hasError && (
        <>
          <div className="answer">
            <MarkdownEditor content={responseValue} {...markdownStyles} />
          </div>
          {allowRegenerate && (
            <IconButton
              size={"small"}
              style={{ fontSize: "12px" }}
              onClick={generateAnswer}
            >
              Regenerate <AutorenewIcon style={{ fontSize: "20px" }} />
            </IconButton>
          )}
        </>
      )}

      {(isGenerating || isLoadingCache || isWaitingToGenerate) && (
        <div>
         
            <Skeleton height={16} />
            <Skeleton height={16} width={120} />
        </div>
      )}

      {hasError && !isGenerating && !isLoadingCache && !isWaitingToGenerate && (
        <div>Could not load details</div>
      )}
    </div>
  );
}