// AIQuestionButton.jsx

import "./AIQuestionButton.scss";
import { Button, IconButton, Skeleton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { promptFullText, getCachedResponse } from "../../requests";
import { useSnackbar } from "../../contexts/SnackbarContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MarkdownEditor from "../utils/MarkdownEditor";
import { useMemo } from "react";


export default function AIQuestionButton({
  autoGenerate = false,
  question,
  selection,
  contexts,
  answer,
  prompt,
  model = "gpt-4o-mini",
  cachingKey = null, // Optional caching key
}: {
  autoGenerate?: boolean; // if true and answer is null, will auto generate answer upon render
  question: string;
  selection: any;
  contexts: any;
  answer?: string | null;
  prompt: any;
  model?: string;
  cachingKey?: string | null;
}) {
  const snackbar = useSnackbar();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoadingCache, setIsLoadingCache] = useState(false); // New state to track cache loading
  const [responseValue, setResponseValue] = useState<string | null>(answer!);
  const hasAutoGenerated = useRef(false); // Track if auto generation has been triggered

  /**
   * Function to load cached answer if cachingKey is provided.
   * Returns true if a cached response was loaded, false otherwise.
   */
  const loadCachedAnswer = async (): Promise<boolean> => {
    if (!cachingKey) return false; // No cachingKey provided; nothing to load

    setIsLoadingCache(true); // Start loading cache
    try {
      const cached = await getCachedResponse(cachingKey);

      // Adjust based on actual structure
      if (cached && cached.response && cached.response.text) {
        setResponseValue(cached.response.text);
        return true; // Cache was successfully loaded
      } else if (cached && cached.text) {
        setResponseValue(cached.text);
        return true; // Alternative structure
      }

      return false; // Cache not found or incorrect structure
    } catch (error) {
      // No cached response found; proceed to generate
      return false;
    } finally {
      setIsLoadingCache(false); // Cache loading complete
    }
  };

  /**
   * Function to generate a new answer.
   * If cachingKey is provided, the response will be cached on the backend.
   */
  const generateAnswer = async () => {
    setIsGenerating(true);
    setResponseValue(null); // Clear current response
    try {
      const response = await promptFullText(selection, contexts, prompt, model, cachingKey);
      setResponseValue(response.text);
    } catch (error) {
      snackbar.showSnackbar("Error sending message", "error");
    } finally {
      setIsGenerating(false);
    }
  };

  /**
   * useEffect to handle auto-generation on component mount.
   * It first attempts to load cached response (if cachingKey is provided).
   * Only generates a new answer if no cached response is found.
   */
  useEffect(() => {
    const handleAutoGenerate = async () => {
      if (
        autoGenerate &&
        !hasAutoGenerated.current // Ensure auto-generation hasn't been triggered yet
      ) {
        if (cachingKey) {
          const cacheLoaded = await loadCachedAnswer();
          if (!cacheLoaded) {
            hasAutoGenerated.current = true; // Prevent further auto-generations
            generateAnswer();
          } else {
            // Cache was loaded successfully; no need to auto-generate
            // Optionally, set hasAutoGenerated.current to true to prevent future attempts
            hasAutoGenerated.current = true;
          }
        } else {
          hasAutoGenerated.current = true; // Prevent further auto-generations
          generateAnswer();
        }
      }
    };

    handleAutoGenerate();
  }, [autoGenerate, selection, cachingKey]); // Removed dependencies that could cause re-execution

  /**
   * Handler for manual regeneration.
   * Forces a new generation, bypassing the cache.
   */
  const handleRegenerate = () => {
    generateAnswer();
  };

  return (
    <div className="aiQuestionButton">
      {/* Display the answer if available */}
      {responseValue && (
        <>
          <div className="answer">
            <MarkdownEditor content={responseValue} />
          </div>
          <IconButton
            size={"small"}
            style={{ fontSize: "12px" }}
            onClick={handleRegenerate} // Use the handler to force regeneration
          >
            Regenerate <AutorenewIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </>
      )}

      {/* Display loading skeletons while generating or loading cache */}
      {(isGenerating || isLoadingCache) && (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}

      {/* Display the question and Ask AI button if no response is present and not loading */}
      {!responseValue && !isGenerating && !isLoadingCache && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <i style={{ fontSize: "14px", flex: "1 1 auto" }}>{question}</i>
          <Button
            size="small"
            variant="outlined"
            style={{ marginLeft: "10px", flexShrink: 0 }}
            onClick={generateAnswer} // Directly generate a new answer
          >
            <AutoAwesomeIcon style={{ marginRight: "5px", fontSize: "14px" }} />
            Ask AI
          </Button>
        </div>
      )}
    </div>
  );
}