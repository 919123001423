// src/apiUtils.ts
import api from "./api";
import { AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * Utility function to make GET requests with optional abort signal.
 */
export const getWithAbort = async <T>(
  url: string,
  config?: AxiosRequestConfig,
  signal?: AbortSignal
): Promise<T> => {
  const response: AxiosResponse<T> = await api.get<T>(url, {
    ...config,
    signal,
  });
  return response.data;
};

/**
 * Utility function to make POST requests with optional abort signal.
 */
export const postWithAbort = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  signal?: AbortSignal
): Promise<T> => {
  const response: AxiosResponse<T> = await api.post<T>(url, data, {
    ...config,
    signal,
  });
  return response.data;
};
