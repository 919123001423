// FloatingYearFilter.tsx
import _ from 'lodash';
import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import YearFilterSlider from './YearFilterSlider';
import IPaperMeta from '../../interfaces/paperMeta';
import MapContext from '../../contexts/MapContext';

interface FloatingYearFilterProps {
  data: IPaperMeta[];
}

const FloatingYearFilter: React.FC<FloatingYearFilterProps> = ({ data }) => {
  const { yearFilter, setYearFilter, sourceJSON, upsertLayer } = useContext(MapContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tempYearRange, setTempYearRange] = useState<[number, number]>([
    yearFilter ? yearFilter.startYear : 1900,
    yearFilter ? yearFilter.endYear : 2025,
  ]);
  const [originalFilter, setOriginalFilter] = useState<{ startYear: number; endYear: number } | null>(yearFilter);

  const buttonRef = useRef<HTMLDivElement>(null);

  // Memoize only the icon-image layout property
  const updatedIconImage = useMemo(() => ({
    "icon-image": [
      "case",
      [
        "all",
        [">=", ["get", "year"], yearFilter ? yearFilter.startYear : 1900],
        ["<=", ["get", "year"], yearFilter ? yearFilter.endYear : 2025],
      ],
      "landmark_red",
      "landmark_grey",
    ],
  }), [yearFilter]);

  useEffect(() => {
    if (!anchorEl) {
      setTempYearRange([
        yearFilter ? yearFilter.startYear : 1900,
        yearFilter ? yearFilter.endYear : 2025,
      ]);
  
      // Handle landmark layers
      const landmarkLayerWithoutTitles = sourceJSON.layers.find(
        (layer) => layer.id === "search_landmarks_without_titles"
      );
      const landmarkSourceWithoutTitles = sourceJSON.sources["search_landmarks_without_titles"];
      
      const landmarkLayerWithTitles = sourceJSON.layers.find(
        (layer) => layer.id === "search_landmarks_with_titles"
      );
      const landmarkSourceWithTitles = sourceJSON.sources["search_landmarks_with_titles"];
  
      if (landmarkLayerWithoutTitles && landmarkSourceWithoutTitles) {
        const existingLayout = landmarkLayerWithoutTitles.layout || {};
        const newLayout = { ...existingLayout, ...updatedIconImage };
        
        if (!_.isEqual(existingLayout["icon-image"], updatedIconImage["icon-image"])) {
          const newLayer = { ...landmarkLayerWithoutTitles, layout: newLayout };
          upsertLayer("search_landmarks_without_titles", newLayer, landmarkSourceWithoutTitles);
        }
      }
  
      if (landmarkLayerWithTitles && landmarkSourceWithTitles) {
        const existingLayout = landmarkLayerWithTitles.layout || {};
        const newLayout = { ...existingLayout, ...updatedIconImage };

        if (!_.isEqual(existingLayout["icon-image"], updatedIconImage["icon-image"])) {
          const newLayer = { ...landmarkLayerWithTitles, layout: newLayout };
          upsertLayer("search_landmarks_with_titles", newLayer, landmarkSourceWithTitles);
        }
      }
  
      // Handle heatmap layer
      const heatmapLayer:any = sourceJSON.layers.find(
        (layer) => layer.id === "search_heatmap"
      );
      const heatmapSource = sourceJSON.sources["search_heatmap"];
  
      if (heatmapLayer && heatmapSource) {
        // Compute filtered IDs based on yearFilter
        const filteredIds = yearFilter
          ? data
              .filter(
                (paper) =>
                  paper.year >= yearFilter.startYear &&
                  paper.year <= yearFilter.endYear
              )
              .map((paper) => paper.id)
          : data.map((paper) => paper.id); // If no filter, include all IDs
  
        // Limit the number of IDs to prevent exceeding argument limits
        // Mapbox may have a limit on the number of arguments for 'in'
        // It's advisable to check Mapbox documentation or consider alternative approaches for very large datasets
        const MAX_FILTER_IDS = 100; // Example limit
        const limitedFilteredIds = filteredIds.slice(0, MAX_FILTER_IDS);
  
        // Create the filter expression
        const newHeatmapFilter = yearFilter
          ? ['in', 'id', ...limitedFilteredIds]
          : ['all']; // If no filter, include all features
  
        // Check if the existing filter is different
        const existingFilter = heatmapLayer.filter;
  
        if (!_.isEqual(existingFilter, newHeatmapFilter)) {
          const newHeatmapLayer = { ...heatmapLayer, filter: newHeatmapFilter };
          upsertLayer("search_heatmap", newHeatmapLayer, heatmapSource);
        }
      }
    }
  }, [yearFilter, anchorEl, upsertLayer, sourceJSON, updatedIconImage, data]);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (!anchorEl) {
      setOriginalFilter(yearFilter);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'year-filter-popper' : undefined;

  const handleSliderChange = (newRange: [number, number]) => {
    setTempYearRange(newRange);
  };

  const handleConfirm = () => {
    setYearFilter(tempYearRange ? { startYear: tempYearRange[0], endYear: tempYearRange[1] } : null);
    setAnchorEl(null);
    setOriginalFilter(null);
  };

  const handleCancel = () => {
    if (originalFilter) {
      setTempYearRange([originalFilter.startYear, originalFilter.endYear]);
    } else {
      setTempYearRange([1900, 2025]);
    }
    setAnchorEl(null);
  };

  const handleReset = () => {
    setTempYearRange([1900, 2025]);
  };

  const handleClickAway = () => {
    if (open) {
      handleCancel();
    }
  };

  const isActive = Boolean(yearFilter);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ 
        position: 'absolute', 
        top: "30px", 
        left: "450px", 
        boxShadow:"0 1px 2px rgba(60,64,67,0.3),0 1px 3px 1px rgba(60,64,67,0.15)",
        zIndex: 1300,
      }}>
        <div
          style={{
            backgroundColor: isActive ? '#ecf3fe' : '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            cursor: 'pointer',
            padding: '8px 12px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize:'14px'
          }}
          onClick={handleButtonClick}
        >
          {isActive ? (
            <CheckIcon sx={{ fontSize: 20, color: "#0b57d0" }} />
          ) : (
            <CalendarTodayIcon sx={{ fontSize: 20 }} />
          )}
          <span style={{ fontWeight: 500, color:isActive? "#0b57d0":"inherit" }}>
            {isActive
              ? `${yearFilter?.startYear} - ${yearFilter?.endYear}`
              : 'Year'}
          </span>
          <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
        </div>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="top-start"
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}
        >
          <Paper
            elevation={4}
            sx={{
              width: 320,
              padding: 2,
              borderRadius: 2,
              boxShadow: '0 2px 12px rgba(0,0,0,0.2)',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Filter by Year
            </Typography>
            <YearFilterSlider
              data={data}
              value={tempYearRange}
              onChange={handleSliderChange}
              minDisplayYear={1850}
              maxDisplayYear={2025}
              showTooltip={false}
            />
            <Divider/>
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button onClick={handleReset}>
                Reset
              </Button>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleConfirm} variant="outlined" color="primary">
                Confirm
              </Button>
            </Stack>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default FloatingYearFilter;