// ClusterHoverPictureDisplay.tsx

import React, { useContext, useState, useEffect, useRef } from "react";
import { CircularProgress, Skeleton, LinearProgress } from "@mui/material";
import MapContext from "../../contexts/MapContext";
import IClusterDetails from "../../interfaces/clusterDetails";
import { generateClusterPicture, fetchClusterDetails } from "../../requests";
import "./ClusterHoverPictureDisplay.scss"; // Import the CSS file for styles

// Centralized Map to track ongoing generation requests
// Key: cluster_id, Value: Promise<IClusterDetails>
const ongoingGenerations: Map<number, Promise<IClusterDetails>> = new Map();

interface ClusterHoverPictureDisplayProps {
  cluster_id: number;
  autoGenerate?: boolean;
  autoGenerateDelay?: number; // Delay in milliseconds before auto-generating
}

const ClusterHoverPictureDisplay: React.FC<ClusterHoverPictureDisplayProps> = ({
  cluster_id,
  autoGenerate = false,
  autoGenerateDelay = 1000, // Default delay of 1 second
}) => {
  const { mapName, setSelectedClusterDetails } = useContext(MapContext);
  const [clusterDetails, setClusterDetails] = useState<IClusterDetails | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [imageKey, setImageKey] = useState<number>(0); // Key to force image refresh
  const [progress, setProgress] = useState<number>(0); // Progress for fake indicator
  const autoGenerateTimeout = useRef<NodeJS.Timeout | null>(null);
  const progressInterval = useRef<NodeJS.Timeout | null>(null);

  // Fetch cluster details when cluster_id or mapName changes
  useEffect(() => {
    let isMounted = true; // To prevent state updates if component unmounts

    const loadDetails = async () => {
      setIsLoading(true);
      try {
        const details = await fetchClusterDetails(mapName, cluster_id);
        if (isMounted) {
          setClusterDetails(details);
        }
      } catch (error) {
        console.error("Error loading cluster details:", error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    loadDetails();

    // Cleanup any pending timeouts when cluster_id or mapName changes
    return () => {
      isMounted = false;
      if (autoGenerateTimeout.current) {
        clearTimeout(autoGenerateTimeout.current);
        autoGenerateTimeout.current = null;
      }
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
        progressInterval.current = null;
      }
      setProgress(0);
    };
  }, [mapName, cluster_id]);

  // Update imageKey when image_url changes to force image reload
  useEffect(() => {
    if (clusterDetails?.image_url) {
      setImageKey((prevKey) => prevKey + 1);
    }
  }, [clusterDetails?.image_url]);

  // Auto-generate image if enabled and no image exists after delay
  useEffect(() => {
    if (
      autoGenerate &&
      clusterDetails &&
      !clusterDetails.image_url &&
      !isGenerating &&
      !isLoading
    ) {
      // Check if a generation request is already pending for this cluster_id
      if (!ongoingGenerations.has(cluster_id)) {
        autoGenerateTimeout.current = setTimeout(() => {
          generatePicture();
        }, autoGenerateDelay);
      }
    }

    // Cleanup the timeout if dependencies change or component unmounts
    return () => {
      if (autoGenerateTimeout.current) {
        clearTimeout(autoGenerateTimeout.current);
        autoGenerateTimeout.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoGenerate,
    clusterDetails,
    isGenerating,
    isLoading,
    autoGenerateDelay,
  ]);

  // Cleanup any pending timeouts when the component unmounts
  useEffect(() => {
    return () => {
      if (autoGenerateTimeout.current) {
        clearTimeout(autoGenerateTimeout.current);
        autoGenerateTimeout.current = null;
      }
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
        progressInterval.current = null;
      }
    };
  }, []);

  const generatePicture = async () => {
    setIsGenerating(true);
    setProgress(0); // Reset progress

    try {
      let generationPromise: Promise<IClusterDetails>;

      // Check if a generation request is already pending
      if (ongoingGenerations.has(cluster_id)) {
        // Await the existing generation request
        generationPromise = ongoingGenerations.get(cluster_id)!;
      } else {
        // Initiate a new generation request and store the Promise
        generationPromise = generateClusterPicture(mapName, cluster_id, "tf");
        ongoingGenerations.set(cluster_id, generationPromise);
      }

      // Start the fake progress indicator
      startFakeProgress();

      const updatedClusterDetails = await generationPromise;
      // Update the context and local state with new details
      setClusterDetails(updatedClusterDetails);

      // Remove the completed request from the Map
      ongoingGenerations.delete(cluster_id);
    } catch (error) {
      console.error("Error generating cluster picture:", error);
      // Remove the failed request from the Map to allow retries
      ongoingGenerations.delete(cluster_id);
    } finally {
      setIsGenerating(false);
      setProgress(100); // Ensure progress is complete
      // Clear the progress interval if it's still running
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
        progressInterval.current = null;
      }
    }
  };

  // Function to start the fake progress indicator
  const startFakeProgress = () => {
    setProgress(0);
    const totalDuration = 5000; // 5 seconds
    const intervalDuration = 100; // Update every 100ms
    const increment = 100 / (totalDuration / intervalDuration); // 2% per interval

    progressInterval.current = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + increment;
        if (newProgress >= 100) {
          clearInterval(progressInterval.current!);
          progressInterval.current = null;
          return 100;
        }
        return newProgress;
      });
    }, intervalDuration);
  };

  // Render logic
  return (
    <div className="clusterHoverPicture" style={{ position: "relative" }}>
    {(isLoading || isGenerating || !clusterDetails?.image_url) ? (
      <div className="skeletonContainer">
        <Skeleton
          variant="rectangular"
          width="100%"
          height="105px"
          animation="wave"
          className="clusterHoverSkeleton"
        />
        { (isGenerating || (autoGenerate && !clusterDetails?.image_url && !isLoading)) && (
          <LinearProgress
            variant="determinate"
            value={progress}
            className="fakeProgress"
          />
        )}
      </div>
    ) : (
      <img
        key={imageKey}
        className="clusterHoverPictureImage"
        src={clusterDetails.image_url}
        alt="Cluster visualization"
      />
    )}
  </div>
  );
};

export default ClusterHoverPictureDisplay;
