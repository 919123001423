import { CircularProgress, IconButton } from "@mui/material";
import { generateClusterPicture } from "../../requests";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useContext, useState } from "react";
import MapContext from "../../contexts/MapContext";
export default function ClusterAIButton({
  clusterId,
  mapName,
}: {
  clusterId: number;
  mapName: string;
}) {
  const [isGenerating, setIsGenerating] = useState(false);
  const { selectedCorpusId, setSelectedClusterDetails } =
    useContext(MapContext);

  const generatePicture = async () => {
    setIsGenerating(true);
    try {
      const updatedClusterDetails = await generateClusterPicture(
        mapName,
        clusterId,
        "tf"
      );
      setSelectedClusterDetails(updatedClusterDetails);
    } catch (e) {
      console.error(e);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <IconButton
      style={{ paddingLeft:'5px',backgroundColor: "white",fontSize:'15px' }}
      size="small"
      disabled={isGenerating}
      onClick={generatePicture}
      className="btn-generate"
    >
      {isGenerating ? <CircularProgress style={{fontSize:'20px'}} size={15} /> : <AutoAwesomeIcon style={{fontSize:'20px'}} />}
    </IconButton>
  );
}
