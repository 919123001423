import { Button } from "@mui/material";
import MapContext from "../contexts/MapContext";
import { useContext } from "react";
import { SIDEBAR_WIDTH } from "../constants";

export default function SideBar({children}: {children?: React.ReactNode}) {
return (<div
style={{
  position:'absolute',
  width: `${SIDEBAR_WIDTH}px`,
  height: "100vh",
  backgroundColor: "#ffffff",
  padding: "10px",
  zIndex:1,
  paddingTop:"90px",
  boxShadow:'1px 1px 4px #c5c5c5',
  boxSizing: "border-box",
}}
>
  {children}
</div>

)
};