// SkeletonPaperListItem.tsx
import React from "react";
import { Skeleton } from "@mui/material";
import './PaperList.scss';

export default function SkeletonPaperListItem() {
  return (
    <div
      className="paperListItemParent"
      style={{
        height: "80px",
        maxHeight: "80px",
        overflowY: "hidden",
        borderBottom: "1px solid lightgrey",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Skeleton variant="text" height={20} width="80%" />
      <Skeleton variant="text" height={20} width="60%" />
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <Skeleton variant="text" height={15} width="30px" />
        <Skeleton variant="text" height={15} width="50px" />
        <Skeleton variant="text" height={15} width="150px" />
      </div>
    </div>
  );
}