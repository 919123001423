// YearFilterSlider.tsx

import React, { useMemo } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import IPaperMeta from '../../interfaces/paperMeta';

interface YearFilterSliderProps {
  data: IPaperMeta[];
  value: [number, number];
  onChange: (newRange: [number, number]) => void;
  minDisplayYear?: number;
  maxDisplayYear?: number;
  showTooltip?: boolean;
}

const YearFilterSlider: React.FC<YearFilterSliderProps> = ({
  data,
  value,
  onChange,
  minDisplayYear = 1900,
  maxDisplayYear = 2025,
  showTooltip = false,
}) => {
  // Extract all years from the data and bound them within display range
  const years = useMemo(
    () =>
      data
        .filter(
          (paper) =>
            paper.year && paper.year >= minDisplayYear && paper.year <= maxDisplayYear
        )
        .map((paper) => paper.year),
    [data, minDisplayYear, maxDisplayYear]
  );

  // Determine the min and max year
  const minYear = useMemo(() => (years.length > 0 ? Math.min(...years) : minDisplayYear), [years, minDisplayYear]);
  const maxYear = useMemo(() => (years.length > 0 ? Math.max(...years) : maxDisplayYear), [years, maxDisplayYear]);

  // Prepare histogram data
  const histogramData = useMemo(() => {
    const counts: { year: number; count: number }[] = [];
    for (let y = minYear; y <= maxYear; y++) {
      counts.push({ year: y, count: 0 });
    }
    years.forEach((year) => {
      const index = year - minYear;
      if (counts[index]) {
        counts[index].count += 1;
      }
    });
    return counts;
  }, [years, minYear, maxYear]);

  // Handle slider change
  const handleChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      onChange([newValue[0], newValue[1]]);
    }
  };

  return (
    <Box width={300} padding={1} boxSizing="border-box">

      {/* Histogram */}
      <Box height={80} width="100%" sx={{ '& .recharts-wrapper': { height: '100% !important' } }}>
        <ResponsiveContainer>
          <BarChart
            data={histogramData}
            margin={{ top: 0, right: 5, left: 5, bottom: 0 }}
          >
            <XAxis dataKey="year" scale="point" hide />
            <YAxis hide domain={[0, 'dataMax + 1']} />
            {showTooltip && (
              <Tooltip
                formatter={(value: number) => [`Count: ${value}`, '']}
                labelFormatter={(label: number) => `Year: ${label}`}
              />
            )}
            <Bar dataKey="count" barSize={10}>
              {histogramData.map((entry) => (
                <Cell
                  key={`cell-${entry.year}`}
                  fill={
                    entry.year >= value[0] && entry.year <= value[1]
                      ? '#e84c3e'
                      : '#ccc'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
      
      {/* Slider */}
      <Box paddingX={0} paddingTop={0} paddingBottom={0} style={{ marginTop: '-11px' }}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="on"
          min={minYear}
          max={maxYear}
          disableSwap
          size="medium"
          sx={{
            '& .MuiSlider-thumb': {
              width: 16,
              height: 16,
              color: '#d4453a',
              opacity: 0.9,
              border: '2px solid grey'
            },
            '& .MuiSlider-track': {
              color: '#e84c3e'
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default YearFilterSlider;