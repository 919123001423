// src/components/MapLayers.tsx

import React from "react";
import { Layer, Source } from "react-map-gl";
import { ISourceJSON } from "../interfaces/mapConfig";

type CombinedLayerType =
  | { id: string; type: "layer"; data: any }
  | { id: string; type: "highlight"; data: any };

interface MapLayersProps {
  combinedLayers: CombinedLayerType[];
  sourceJSON: ISourceJSON;
}

const MapLayers: React.FC<MapLayersProps> = ({ combinedLayers, sourceJSON }) => (
  <>
    {combinedLayers
      .slice()
      .reverse()
      .map((item, r_index) => {
        const index = combinedLayers.length - 1 - r_index;
        const layer = item.data;
        const beforeId = index < combinedLayers.length - 1 ? combinedLayers[index + 1].id : undefined;

        if (item.type === "layer") {
          return <Layer key={layer.id} {...layer} beforeId={beforeId} />;
        } else if (item.type === "highlight") {
          const source = sourceJSON.sources[layer.source];
          return (
            <Source id={layer.id} key={layer.id} {...(source as any)}>
              <Layer key={layer.id} {...layer} beforeId={beforeId} />
            </Source>
          );
        }
      })}
  </>
);

export default MapLayers;