// frontend/src/components/AIQuestionFunctionCallingInline.jsx

import { Skeleton } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import {
  promptFunctionCalling,
  getCachedResponse,
} from "../../requests";
import { useSnackbar } from "../../contexts/SnackbarContext";
import MarkdownEditor from "../utils/MarkdownEditor";

export default function AIQuestionFunctionCallingInline({
  autoGenerate = false,
  autoGenerateDelay = 0, // Optional delay for auto-generation in milliseconds
  selection,
  contexts,
  answer,
  prompt,
  promptingConfig, // Configuration for function calling
  renderer, // Custom renderer for the response
  model = "gpt-4o-mini",
  cachingKey = null, // Optional caching key
}: {
  autoGenerate?: boolean; // If true and answer is null, will auto generate answer upon render
  autoGenerateDelay?: number; // Delay in milliseconds before auto-generating the answer
  selection: any;
  contexts: any;
  answer?: string | null;
  prompt: any;
  promptingConfig: any;
  renderer?: (response: any) => JSX.Element; // Renderer function to display the response
  model?: string;
  cachingKey?: string | null;
}) {
  const snackbar = useSnackbar();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoadingCache, setIsLoadingCache] = useState(false); // State to track cache loading
  const [responseValue, setResponseValue] = useState<string | null>(answer!);
  const [hasError, setHasError] = useState(false);
  const hasAutoGenerated = useRef(false); // Track if auto generation has been triggered
  const abortController = useRef<AbortController | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMounted = useRef(true); // Track if component is mounted

  useEffect(() => {
    // Set isMounted to true when component mounts
    isMounted.current = true;

    // Cleanup function
    return () => {
      // Set isMounted to false when component unmounts
      isMounted.current = false;

      // Clear any pending timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Abort any ongoing requests
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, []);

  /**
   * Function to load cached answer if cachingKey is provided.
   * Returns true if a cached response was loaded, false otherwise.
   */
  const loadCachedAnswer = async (): Promise<boolean> => {
    if (!cachingKey) return false; // No cachingKey provided; nothing to load

    if (!isMounted.current) return false; // Prevent actions if unmounted

    setIsLoadingCache(true);
    setHasError(false);
    abortController.current = new AbortController();

    try {
      const cached = await getCachedResponse(
        cachingKey,
        abortController.current.signal
      );

      if (!isMounted.current) return false; // Prevent state updates if unmounted

      if (
        cached &&
        cached.response &&
        cached.response[promptingConfig.property_name]
      ) {
        setResponseValue(cached.response[promptingConfig.property_name]);
        return true; // Cache was successfully loaded
      }

      return false; // Cache not found or incorrect structure
    } catch (error) {
      if (
        !(error instanceof DOMException && error.name === "AbortError") &&
        isMounted.current
      ) {
        setHasError(true);
      }
      return false;
    } finally {
      if (isMounted.current) {
        setIsLoadingCache(false);
      }
    }
  };

  /**
   * Function to generate a new answer using function calling.
   * If cachingKey is provided, the response will be cached on the backend.
   */
  const generateAnswer = async () => {
    if (!isMounted.current) return; // Prevent action if unmounted

    setIsGenerating(true);
    setResponseValue(null); // Clear current response
    setHasError(false);
    abortController.current = new AbortController();

    try {
      const response = await promptFunctionCalling(
        selection,
        contexts,
        prompt,
        promptingConfig,
        model,
        cachingKey,
        abortController.current.signal
      );

      if (!isMounted.current) return; // Prevent state updates if unmounted

      setResponseValue(response[promptingConfig.property_name]);
    } catch (error) {
      if (
        !(error instanceof DOMException && error.name === "AbortError") &&
        isMounted.current
      ) {
        setHasError(true);
        //snackbar.showSnackbar("Error generating response", "error");
      }
    } finally {
      if (isMounted.current) {
        setIsGenerating(false);
      }
    }
  };

  useEffect(() => {
    const handleAutoGenerate = async () => {
      if (autoGenerate && !hasAutoGenerated.current) {
        if (cachingKey) {
          const cacheLoaded = await loadCachedAnswer();
          if (!cacheLoaded) {
            hasAutoGenerated.current = true;
            if (autoGenerateDelay > 0) {
              timeoutRef.current = setTimeout(() => {
                if (isMounted.current) {
                  generateAnswer();
                }
              }, autoGenerateDelay);
            } else {
              generateAnswer();
            }
          } else {
            hasAutoGenerated.current = true;
          }
        } else {
          hasAutoGenerated.current = true;
          if (autoGenerateDelay > 0) {
            timeoutRef.current = setTimeout(() => {
              if (isMounted.current) {
                generateAnswer();
              }
            }, autoGenerateDelay);
          } else {
            generateAnswer();
          }
        }
      }
    };

    handleAutoGenerate();

    // Cleanup is handled in the first useEffect
  }, [
    autoGenerate,
    autoGenerateDelay,
    selection,
    contexts,
    cachingKey,
    prompt,
    promptingConfig,
    model,
  ]);

  return (
    <div className="aiQuestionFunctionCallingInline">
      {/* Display the answer if available and no error */}
      {responseValue && !hasError && (
        <div className="answer">
          {renderer
            ? renderer(responseValue)
            : <MarkdownEditor content={responseValue} />}
        </div>
      )}

      {/* Display loading skeletons while generating or loading cache */}
      {(isGenerating || isLoadingCache) && (
        <div>
          {Array.from({ length: 2 }).map((_, index) => (
            <Skeleton key={index} height={16} />
          ))}
        </div>
      )}

      {/* Display error message if there's an error */}
      {hasError && !isGenerating && !isLoadingCache && (
        <div>Could not load details</div>
      )}

      {/* Note: No button is rendered in this inline version */}
    </div>
  );
}