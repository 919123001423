import { Button } from "@mui/material";
import _ from "lodash";
import { StyleSpecification } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { LayerProps } from "react-map-gl";
import type { MapRef } from "react-map-gl/maplibre";
import Map from "react-map-gl/maplibre";
import { useLocation } from "react-router-dom";
import { GOOGLE_MAPS_COLORS } from "../colors";
import MapContext, { IHighlightLayer } from "../contexts/MapContext";
import { useSnackbar } from "../contexts/SnackbarContext";
import {
  createLandmarkLayerConfig,
  createLandmarkLayerProps,
} from "../createLandmarkLayer";
import {
  createPolygonConfig,
  createPolygonLayerProps,
} from "../createPolygonLayer";
import onMapClick from "../handlers/onMouseClick";
import useFeatureSelection from "../hooks/useFeatureSelection";
import { useSearch } from "../hooks/useSearch";
import useViewport from "../hooks/useViewport";
import { ILayer, IMapConfig, ISourceJSON } from "../interfaces/mapConfig";
import { fetchPaperDetails } from "../requests";
import AreaSearch from "./AreaSearch";
import FloatingYearFilterButton from "./filter/FloatingYearFilterButton";
import MapLayers from "./MapLayers";
import MapPopups from "./MapPopup";
import "./MapStyles.scss";
import CopySelectionButton from "./utils/CopySelectionButton";


const MIN_ZOOM_FOR_AREA_SEARCH = 5;

// Define delay constants
const POPUP_DELAY_AFTER_VIEWPORT_CHANGE = 400; // milliseconds
const POPUP_DELAY_AFTER_INTERACTION = 20; // milliseconds

type CombinedLayerType =
  | { id: string; type: "layer"; data: LayerProps }
  | { id: string; type: "highlight"; data: LayerProps };

const DEFAULT_CONTAINER_STYLE = {
  backgroundImage: `
  linear-gradient(45deg, ${GOOGLE_MAPS_COLORS.loadingBackground} 25%, transparent 25%),
  linear-gradient(-45deg, ${GOOGLE_MAPS_COLORS.loadingBackground} 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, ${GOOGLE_MAPS_COLORS.loadingBackground} 75%),
  linear-gradient(-45deg, transparent 75%, ${GOOGLE_MAPS_COLORS.loadingBackground}75%)`,
  backgroundSize: "20px 20px",
  backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
  width: "100vw",
  height: "100vh",
  cursor: "default",
};

const MapReactMap = ({
  config,
  sourceJSON,
}: {
  config: IMapConfig;
  sourceJSON: ISourceJSON;
}) => {
  const {
    darkmode,
    viewport,
    setViewport,
    zoom,
    targetCenter,
    setZoom,
    selectedCorpusId,
    searchPaperResults,
    setSelectedCorpusId,
    sidebarOpen,
    setSelectedCluster,
    selectedAuthorId,
    selectedAuthorDetails,
    selectedClusterDetails,
    selectedCluster,
    hoveredPaper,
    upsertLayer,
    removeLayer,
    setPromptingSelection,
    searchQuery,
    mapName,
    setSelectedClusterDetails,
    setHoveredPaper,
    hoveredCluster,
    selectedPaperDetails,
    setSelectedPaperDetails,
    setHoveredCluster,
    setMapRef, // Function to set mapRef in context
  } = useContext(MapContext);
  const snackbar = useSnackbar();
  const [cursor, setCursor] = useState<string>("default");
  const { loading, search } = useSearch();
  const location = useLocation();
  const isDebugMode = location.search.includes("debug");
  const hoverDelayTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [paperRectangleHoverTimeout, setPaperRectangleHoverTimeout] =
    useState<any>(null); // Timeout for paper rectangle hover
  const [hoveredPaperRectangleId, setHoveredPaperRectangleId] = useState<
    number | null
  >(null); // ID of the hovered paper rectangle
  const [containerStyle, setContainerStyle] = useState<any>(
    DEFAULT_CONTAINER_STYLE
  );

  const [lastMouseOverFeature, setLastMouseOverFeature] = useState<{
    id: number;
    source: string;
    sourceLayer: string;
  } | null>(null);
  const [lastSelectedFeature, setLastSelectedFeature] = useState<{
    id: number;
    source: string;
    sourceLayer: string;
  } | null>(null);

  const [mapStyle, setMapStyle] = useState<StyleSpecification>({
    version: 8,
    sources: {},
    layers: [],
  });

  const [selectedFeature, setSelectedFeature] = useState({
    id: null,
    type: null, // 'corpus' or 'cluster'
  });

  const [map, setMap] = useState<any>(null);

  const mapRefLocal = useRef<MapRef | null>(null);

  // State to track if the user has interacted within the current viewport
  const [hasInteractionInViewport, setHasInteractionInViewport] = useState(false);

  // Refs to store timeout IDs
  useEffect(() => {
    // Set the mapRef in context for external control
    if (mapRefLocal.current) {
      setMapRef(mapRefLocal.current as any);
    }
  }, [mapRefLocal, setMapRef]);

 
   // Initialize viewport and map reference
   const { handleMove } = useViewport(mapRefLocal, setMapRef,setHasInteractionInViewport,hoverDelayTimeoutRef);

   // Handle feature selection
   useFeatureSelection(mapRefLocal.current, selectedFeature, config);

  // Handle feature selection
  useFeatureSelection(mapRefLocal.current, selectedFeature, config);
  // Handle hover effects
  /*useHoverEffects({
    map: mapRefLocal.current,
    hoveredPaper,
    setHoveredPaper,
    hoveredCluster,
    setHoveredCluster,
    config,
    hasInteractionInViewport,
    setHasInteractionInViewport,
    upsertLayer,
    removeLayer,
    setCursor,
  });    */
  const getSourceForLayerId = (sourceLayer: string) => {
    if (sourceLayer in sourceJSON.sources) {
      return sourceLayer;
    } else {
      return "default";
    }
  };
  const hasClusterLayerPrefix = (sourceLayer: string) => {
    return config.cluster_layer_prefixes?.some((prefix) =>
      sourceLayer.startsWith(prefix)
    );
  };

  const assesIsClusterLabelLayer = (sourceLayer: string) => {
    return (
      hasClusterLayerPrefix(sourceLayer) && sourceLayer.endsWith("_labels")
    );
  };

  const assessIsCorpusFeature = (feature: any) => {
    const validLayers = [
      "author_papers",
      "author_citations",
      "paper_references",
      "paper_citations",
      "author_references",
      "paper_labels",
      "selected_paper",
      "paper_polygons"
    ]
    
    return validLayers.includes(feature.sourceLayer) || validLayers.includes(feature.source) || feature.source?.startsWith("search_landmarks") || feature.sourceLayer?.startsWith("highlight_");
  };


  const resetLastMouseOverFeatureHover = (feature: any) => {
    if (lastMouseOverFeature && map) {
      map.setFeatureState({ source: lastMouseOverFeature.source, id: lastMouseOverFeature.id, sourceLayer: lastMouseOverFeature.sourceLayer }, { hover: false });
    }
  };

  const setMouseOverFeatureHover = (feature: any) => {
    if (map) {
      map.setFeatureState({ source: feature.source, id: feature.id, sourceLayer: feature.sourceLayer }, { hover: true });
    }
  };


  useEffect(() => {
    if (mapRefLocal.current) {
      const _map = mapRefLocal.current.getMap();
      // Set default cursor (pointer is set by mapbox-gl on hover over features)
      _map.getCanvas().style.cursor = "default";

      // Usage
      const colors = ["red", "yellow", "grey", "blue", "lila", "green"];
      colors.forEach((color) => addCustomIcon(_map, color));
      _map.on("zoom", () => {
        setZoom(_map.getZoom());
      });

      const handleViewportChangeInternal = () => {
        const bounds = _map.getBounds();

        setViewport({
          sw: bounds.getSouthWest(),
          ne: bounds.getNorthEast(),
          center: { lng: bounds.getCenter().lng, lat: bounds.getCenter().lat },
          zoom: _map.getZoom(),
        });
      };
      _map.on("zoom", handleViewportChangeInternal);
      _map.on("dragend", handleViewportChangeInternal);
      setMap(_map);
    }
  }, [mapRefLocal, mapRefLocal.current, targetCenter]);

  useEffect(() => {
    const SIDEBAR_WIDTH = 0;
    // Function to update the container width
    const updateContainerWidth = () => {
      const sidebarWidth = sidebarOpen ? SIDEBAR_WIDTH : 0; // Assume sidebar width is 500px
      const newWidth = window.innerWidth - sidebarWidth;
      setContainerStyle({
        ...containerStyle,
        left: sidebarOpen ? `${SIDEBAR_WIDTH}px` : "0px",
        width: `${newWidth}px`,
        backgroundColor: darkmode ? "#222" : "#d5e4ff",
      });
    };

    // Call it initially and whenever sidebarOpen changes
    updateContainerWidth();

    // Optional: Adjust the width when the window resizes
    window.addEventListener("resize", updateContainerWidth);
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, [sidebarOpen, darkmode]); // Only re-run when sidebarOpen or darkmode changes

  const resetFeatureState = (featureId: any, featureType: any) => {
    const state = { select: false }; // Resetting the selection state

    if (map) {
      // Ensure the map object is defined
      if (featureType === "cluster") {
        for (const clusterLayerPrefix of config.cluster_layer_prefixes ?? []) {
          const clusterLabelLayerId = clusterLayerPrefix + "_labels";
          const clusterLineLayerId = clusterLayerPrefix + "_line";
          const clusterAreaLayerId = clusterLayerPrefix + "_area";

          const paramLabels = {
            source: getSourceForLayerId(clusterLabelLayerId),
            id: featureId,
            sourceLayer: clusterLabelLayerId,
          };

          const paramArea = {
            source: getSourceForLayerId(clusterAreaLayerId),
            id: featureId,
            sourceLayer: clusterAreaLayerId,
          };

          const paramStroke = {
            source: getSourceForLayerId(clusterLineLayerId),
            id: featureId,
            sourceLayer: clusterLineLayerId,
          };

          try {
            map.setFeatureState(paramLabels, state);
          } catch (e) {
            console.log(e);
          }

          try {
            map.setFeatureState(paramArea, state);
          } catch (e) {
            console.log(e);
          }
          try {
            map.setFeatureState(paramStroke, state);
          } catch (e) {
            console.log(e);
          }
        }
      } else if (featureType === "corpus") {
        // Reset state for the corpus layer
        try {
          map.setFeatureState(
            { source: "default", id: featureId, sourceLayer: "paper_labels" },
            state
          );
        } catch (e) {
          console.log(e);
        }
        try {
          if (map.getLayer("search_landmarks_with_titles")) {
            map.setFeatureState(
              { source: "search_landmarks_with_titles", id: featureId },
              state
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const selectFeature = (featureId: any, featureType: any) => {
    // Reset the state of previously selected feature
    if (selectedFeature.id && map) {
      resetFeatureState(selectedFeature.id, selectedFeature.type);
    }

    // Set the new feature as selected
    setSelectedFeature({ id: featureId, type: featureType });
    setFeatureState(featureId, featureType, true); // true for selected
  };

  const deselectFeature = () => {
    if (selectedFeature.id && map) {
      resetFeatureState(selectedFeature.id, selectedFeature.type);
    }
    setSelectedFeature({ id: null, type: null });
  };

  useEffect(() => {
    if (mapRefLocal.current) {
      // Check if there's a new corpus ID selected
      if (selectedCorpusId) {
        selectFeature(selectedCorpusId, "corpus");
      } else {
        // If no corpus is selected, ensure any previously selected corpus is deselected
        if (selectedFeature.type === "corpus") {
          deselectFeature();
        }
      }
    }
  }, [mapRefLocal, selectedCorpusId]); // Only re-run when selectedCorpusId changes

  useEffect(() => {
    if (selectedPaperDetails) {
      const layerProps = createLandmarkLayerProps(
        createLandmarkLayerConfig({
          type: "landmark",
          source: "selected_paper",
          layer_name: "selected_paper",
          tag: "selected_paper",
          min_zoom: 0,
          max_zoom: 24,
        }),
        false,
        true
      );
      const selectedPaperHighlight: IHighlightLayer = {
        source: {
          id: "selected_paper",
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: selectedPaperDetails.geometry,
              },
            ],
          },
        },
        layer: layerProps,
      };
      upsertLayer(
        "selected_paper",
        selectedPaperHighlight.layer as any,
        selectedPaperHighlight.source
      );
    } else {
      removeLayer("selected_paper");
    }
  }, [selectedPaperDetails]);

  useEffect(() => {
    // Check if there's a new cluster ID selected
    let clusterData = selectedCluster;

    if (
      selectedClusterDetails &&
      selectedClusterDetails.cluster_id !== selectedCluster?.cluster_id
    ) {
      // Case where new cluster was selected, but the details are not replaced/reset
      return;
    }
    if (selectedClusterDetails) {
      clusterData = selectedClusterDetails; // Contains more information (e.g., geometry)
      const bounding_box = selectedClusterDetails.bounding_box;
      if (!bounding_box) {
        snackbar.showSnackbar("No bounding box found for cluster", "error");
        return;
      }
      const bounds = [
        bounding_box.coordinates[0][0][0],
        bounding_box.coordinates[0][0][1],
        bounding_box.coordinates[0][2][0],
        bounding_box.coordinates[0][2][1],
      ];

      clusterData["bounds"] = bounds;
    }
    if (clusterData) {
      selectFeature(clusterData.cluster_id, "cluster");

      if (clusterData.bounds) {
        // Bounds are min_lng, min_lat, max_lng, max_lat
        const bounds = clusterData.bounds;
        // Fit Bounds
        map.fitBounds(
          [
            [bounds[0], bounds[1]],
            [bounds[2], bounds[3]],
          ],
          { padding: 100 }
        );
      }
      if (clusterData.geometry) {
        const layerProps = createPolygonLayerProps(
          createPolygonConfig({
            type: "polygon",
            source: "selected_cluster",
            layer_name: "selected_cluster",
            tag: "selected_cluster",
            min_zoom: 0,
            max_zoom: 24,
          }),
          true
        );
        const selectedClusterHighlight: IHighlightLayer = {
          source: {
            id: "selected_cluster",
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: clusterData.geometry,
                },
              ],
            },
          },
          layer: layerProps,
        };
        // Add geometry to highlightFeatures
        upsertLayer(
          "selected_cluster",
          selectedClusterHighlight.layer as any,
          selectedClusterHighlight.source
        );
      }
    } else {
      removeLayer("selected_cluster");

      // If no cluster is selected, ensure any previously selected cluster is deselected
      if (selectedFeature.type === "cluster") {
        deselectFeature();
      }
    }
  }, [selectedCluster?.cluster_id, selectedClusterDetails?.cluster_id]); // Only re-run when selectedClusterId changes

  const setFeatureState = (
    featureId: any,
    featureType: any,
    isSelected: any
  ) => {
    for (const clusterLayerPrefix of config.cluster_layer_prefixes ?? []) {
      const clusterLabelLayerId = clusterLayerPrefix + "_labels";
      const clusterLineLayerId = clusterLayerPrefix + "_line";
      const clusterAreaLayerId = clusterLayerPrefix + "_area";

      const paramLabels = {
        source: getSourceForLayerId(clusterLabelLayerId),
        id: featureId,
        sourceLayer: clusterLabelLayerId,
      };

      const paramArea = {
        source: getSourceForLayerId(clusterAreaLayerId),
        id: featureId,
        sourceLayer: clusterAreaLayerId,
      };

      const paramStroke = {
        source: getSourceForLayerId(clusterLineLayerId),
        id: featureId,
        sourceLayer: clusterLineLayerId,
      };

      try {
        map.setFeatureState(paramLabels, { select: isSelected });
      } catch (e) {
        console.log(e);
      }

      try {
        map.setFeatureState(paramArea, { select: isSelected });
      } catch (e) {
        console.log(e);
      }
      try {
        map.setFeatureState(paramStroke, { select: isSelected });
      } catch (e) {
        console.log(e);
      }
    }
    const state = { select: isSelected };

    if (featureType === "corpus") {
      try {
        map.setFeatureState(
          { source: "default", id: featureId, sourceLayer: "paper_labels" },
          state
        );
      } catch (e) {
        console.log(e);
      }

      if (map.getLayer("search_landmarks_with_titles")) {
        try {
          map.setFeatureState(
            { source: "search_landmarks_with_titles", id: featureId },
            state
          );
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const [mapViewport, setMapViewport] = useState({
    latitude: targetCenter ? targetCenter.lat : 0,
    longitude: targetCenter ? targetCenter.lng : 0,
    zoom: targetCenter ? targetCenter.zoom : 1,
    maxBounds: [
      [-130, -90],
      [130, 90],
    ],
    minZoom: config.min_zoom,
    maxZoom: config.max_zoom, //!!config.max_zoom ? config.max_zoom - 0.001 : 24,
  });
  useEffect(() => {
    // Function to remove highlight layers if they exist
    function removeHighlightLayersIfExist() {
      const highlightTypes = [
        "author_paper",
        "author_citation",
        "author_reference",
        "paper_citation",
        "paper_reference",
        "search",
        "cluster",
      ];
      for (const highlightType of highlightTypes) {
        removeLayer("hovered_" + highlightType);
      }
    }

    // This effect should only run when `hoveredPaper` changes
    if (hoveredPaper && hoveredPaper.source === "list") {
      const corpusId = "hovered_paper_" + hoveredPaper.id.toString();
      /*const highlightPresent = highlightLayers.some(
        (highlightLayer) =>
          highlightLayer.layer.id === "hovered_paper__" + corpusId
      );*/

      const layerProps = createLandmarkLayerProps(
        createLandmarkLayerConfig({
          type: "landmark",
          source: "hovered_paper",
          layer_name: "hovered_" + hoveredPaper.type,
          tag: "hover",
          min_zoom: 0,
          max_zoom: 24,
        }),
        false,
        true
      );

      const hoverHighlight = {
        source: {
          //id: "hovered_paper",
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {
                  title: "", //TODO make sure the text is not actually displayed
                },
                geometry: hoveredPaper.geometry,
              },
            ],
          },
        },
        layer: layerProps,
      };

      upsertLayer(
        layerProps.id,
        hoverHighlight.layer as any,
        hoverHighlight.source
      );
    } else {
      removeHighlightLayersIfExist();
    }
  }, [hoveredPaper]); // Only depend on `hoveredPaper`

  useEffect(() => {
    //only update/initialize when the default source changed (or glyphs). Otherwise entire map will rerender everytime a geojson source was changed
    //NOTE: assumes that the tile source is called 'default'
    setMapStyle({
      version: sourceJSON.version as any,
      glyphs: sourceJSON.glyphs,
      sources: sourceJSON.sources,
      layers: [], //will be set as children of the react component
    });
  }, [sourceJSON.sources.default, sourceJSON.glyphs]);



  const handleMouseMove = useCallback(
    (event: any) => {
      const feature = event.features && event.features[0];

      if (feature && feature.id) {

        // Determine if the feature is interactive (cluster, paper, landmark, etc.)
        const isCluster = hasClusterLayerPrefix(feature.sourceLayer ?? "");
        const isLabelLayer = isCluster && assesIsClusterLabelLayer(feature.sourceLayer ?? "");
        const isCorpusFeature =assessIsCorpusFeature(feature)
  
        const _currentMouseOverFeature = {
          id: feature.id,
          source:feature.source,
          sourceLayer: feature.sourceLayer,
        };
        
        if(lastMouseOverFeature && lastMouseOverFeature?.id !== _currentMouseOverFeature.id) {
          resetLastMouseOverFeatureHover(lastMouseOverFeature);
          setMouseOverFeatureHover(_currentMouseOverFeature);
          setLastMouseOverFeature(_currentMouseOverFeature);
        }else if(lastMouseOverFeature?.id !== _currentMouseOverFeature.id) {
          setMouseOverFeatureHover(_currentMouseOverFeature);
          setLastMouseOverFeature(_currentMouseOverFeature);
        }
        // Function to handle hover with delay
        const handleHoverWithDelay = (hoverAction: () => void) => {
          // Clear existing hover delay timeout
          if (hoverDelayTimeoutRef.current) {
            clearTimeout(hoverDelayTimeoutRef.current);
            hoverDelayTimeoutRef.current = null;
          }

          const delay = hasInteractionInViewport
            ? POPUP_DELAY_AFTER_INTERACTION
            : POPUP_DELAY_AFTER_VIEWPORT_CHANGE;

          hoverDelayTimeoutRef.current = setTimeout(() => {
            hoverAction();
            if (!hasInteractionInViewport) {
              setHasInteractionInViewport(true);
            }
          }, delay);
        };

        if (isCluster) {
          if (isLabelLayer) {
            handleHoverWithDelay(() => {
              setHoveredCluster({
                cluster_id: feature.id,
                source: "label",
                label: `${feature.properties.label}`,
                geometry: feature.geometry,
              });
            });
          } else {
            handleHoverWithDelay(() => {
              setHoveredCluster({
                cluster_id: feature.id,
                source: "map",
              });
            });
          }
          setHoveredPaper(null);
        } else if (isCorpusFeature) {
          handleHoverWithDelay(() => {
            let hoverType = feature.sourceLayer || feature.source;
            if (hoverType.startsWith("highlight_")) {
              hoverType = "highlight";
            }
            if (hoveredPaper?.id !== feature.id) {
              setHoveredPaper({
                id: feature.id,
                source: "map",
                geometry: feature.geometry,
                title: feature.properties.title,
                type: hoverType,
                meta: null,
              });
            }
            setHoveredCluster(null);
          });

         
        } else if (feature.sourceLayer === "papers") {
          // Handle hover for paper rectangles
          if (
            hoveredPaperRectangleId !== feature.id &&
            (!hoveredPaper || hoveredPaper.id !== feature.id)
          ) {
            if (paperRectangleHoverTimeout) {
              clearTimeout(paperRectangleHoverTimeout);
            }
            setHoveredPaperRectangleId(feature.id);
            const newTimeout = setTimeout(async () => {
              try {
                const paperDetails = await fetchPaperDetails(feature.id);

                const polygonFeature = feature.geometry;
                // Create point geometry from polygon (centroid)
                const pointGeometry: any = {
                  type: "Point",
                  coordinates: [
                    // Mean
                    _.mean(
                      polygonFeature.coordinates[0]
                        .map((coord: any) => coord[0])
                        .slice(0, 4)
                    ),
                    _.mean(
                      polygonFeature.coordinates[0]
                        .map((coord: any) => coord[1])
                        .slice(0, 4)
                    ),
                  ],
                };

                const hoveredPaperMeta = {
                  journal: paperDetails.journal,
                  citationcount: paperDetails.citationcount,
                  authors: paperDetails.authors,
                  year: paperDetails.year,
                  tldr: paperDetails.tldr,
                };
                setHoveredPaper({
                  ...paperDetails,
                  geometry: pointGeometry,
                  source: "map",
                  meta: hoveredPaperMeta,
                });
                setHoveredPaperRectangleId(null); // Clear the rectangle ID once the action is triggered
              } catch (e) {
                snackbar.showSnackbar(
                  "Failed to fetch hover information",
                  "error"
                );
              }
            }, 200);
            setPaperRectangleHoverTimeout(newTimeout);
          }
        }
        setCursor("pointer");
      } else {
        if (lastMouseOverFeature) {
          resetLastMouseOverFeatureHover(lastMouseOverFeature);
        }
        setLastMouseOverFeature(null);
        // Clear all hovers and reset state
        if (paperRectangleHoverTimeout) {
          clearTimeout(paperRectangleHoverTimeout);
        }
        setHoveredPaperRectangleId(null);
        setHoveredPaper(null);
        setHoveredCluster(null);
        setCursor("default");

        // Clear any pending hover delays
        if (hoverDelayTimeoutRef.current) {
          clearTimeout(hoverDelayTimeoutRef.current);
          hoverDelayTimeoutRef.current = null;
        }
        
      }
    },
    [
      lastMouseOverFeature,
      hoveredPaperRectangleId,
      hoveredPaper,
      paperRectangleHoverTimeout,
      hasInteractionInViewport,
      snackbar,
    ]
  );

   // Handle Map Click
   const handleMapClick = useCallback(
    (event: any) => {
      
      onMapClick(event, { setSelectedCluster, setSelectedCorpusId, setSelectedClusterDetails }, config);
    },
    [setSelectedCluster, setSelectedCorpusId, setSelectedClusterDetails, config]
  );

   // useEffect for hovered papers
   useEffect(() => {
    if (hoveredPaper && hoveredPaper.source === "map" && map) {
      // Set the hover state for the hovered paper
      const paramsPaperLabels = {
        source: "default",
        id: hoveredPaper.id,
        sourceLayer: "paper_labels",
      };

      const paramsSeachLandmarks = {
        source: "search_landmarks_with_titles",
        id: hoveredPaper.id,
      };

      try {
        map.setFeatureState(paramsPaperLabels, { hover: true });
        if (map.getLayer("search_landmarks_with_titles")) {
          map.setFeatureState(paramsSeachLandmarks, { hover: true });
        }
      } catch (e) {
        console.log(e);
      }
      
      return () => {
        // Reset the hover state when the hoveredPaper changes
        try {
          map.setFeatureState(paramsPaperLabels, { hover: false });
        if (map.getLayer("search_landmarks_with_titles")) {
          map.setFeatureState(paramsSeachLandmarks, { hover: false });
        }
        } catch (e) {
          console.log(e);
        }
      };
    }
  }, [hoveredPaper, map]);

  // useEffect for hovered clusters
  useEffect(() => {
    if (hoveredCluster && map) {
      if (hoveredCluster.source === "list" && hoveredCluster.geometry) {
        const layerProps = createPolygonLayerProps(
          createPolygonConfig({
            type: "polygon",
            source: "hovered_cluster",
            layer_name: "hovered_cluster",
            tag: "hovered_cluster",
            min_zoom: 0,
            max_zoom: 24,
          }),
          true
        );
        const hoveredClusterHighlight: IHighlightLayer = {
          source: {
            id: "hovered_cluster",
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: hoveredCluster.geometry,
                },
              ],
            },
          },
          layer: layerProps,
        };

        // Add geometry to highlightFeatures
        upsertLayer(
          "hovered_cluster",
          hoveredClusterHighlight.layer as any,
          hoveredClusterHighlight.source
        );
      }

      // Cleanup function to reset hover state
      return () => {
      };
    } else if (!hoveredCluster || hoveredCluster.source !== "list") {
      removeLayer("hovered_cluster"); 
    }
  }, [hoveredCluster, map]);

  const addCustomIcon = async (map: any, color: string) => {
    try {
      const image = await new Promise((resolve, reject) => {
        const img = new Image(); // Create a new Image instance
        img.onload = () => resolve(img); // Resolve the promise once the image has loaded
        img.onerror = reject; // Reject the promise if there's an error loading the image
        img.src = `/landmark_${color}.png`; // Set the source of the image
      });

      if (!map.hasImage(`landmark_${color}`)) {
        map.addImage(`landmark_${color}`, image as any); // Add the custom icon to the map
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  let combinedLayers: CombinedLayerType[] = [
    ...sourceJSON.layers
      .filter((layer: ILayer) => layer.visible ?? true)
      .map((layer: any) => {
        if ("source-layer" in layer) {
          return {
            id: layer.id,
            type: "layer" as any,
            data: layer,
          };
        } else {
          return {
            id: layer.id,
            type: "highlight" as any,
            data: layer,
          };
        }
      }),
  ];

  // Custom Logic: exclude highlight layers when there is a search result being displayed
  if (searchPaperResults || selectedAuthorId || selectedCorpusId) {
    combinedLayers = combinedLayers.filter(
      (layer) => !layer.id.startsWith("highlight_")
    );
  }

  if (selectedAuthorId || selectedCorpusId) {
    combinedLayers = combinedLayers.filter(
      (layer) => layer.id.indexOf("search") === -1
    );
  }

  return (
    <Map
      ref={mapRefLocal}
      mapStyle={mapStyle}
      initialViewState={mapViewport}
      onMove={handleMove}
      style={containerStyle}
      onMouseMove={handleMouseMove}
      cursor={cursor}
      onClick={handleMapClick}
      interactiveLayerIds={[
        "paper_labels",
        "papers",
        "author_papers",
        "author_citations",
        "author_references",
        "paper_citations",
        "paper_references",
        "search_landmarks_with_titles",
        "search_landmarks_without_titles",
        "highlight_0",
        "highlight_1",
        "highlight_2",
        "highlight_3",
        "highlight_4",
        "highlight_5",
        "highlight_6",
        "highlight_7",
        "highlight_8",
        "highlight_9",
        "selected_paper",
        // Add cluster layer IDs based on config
        ...(config.cluster_layer_prefixes?.flatMap((prefix) => [
          `${prefix}_labels`,
          `${prefix}_areas`,
          `${prefix}_lines`,
        ]) || []),
      ]}
      attributionControl={false}
    >
      <MapLayers combinedLayers={combinedLayers} sourceJSON={sourceJSON} />
      <MapPopups hoveredPaper={hoveredPaper} hoveredCluster={hoveredCluster} />
      {searchPaperResults && <FloatingYearFilterButton data={searchPaperResults} />}
      {isDebugMode && searchPaperResults && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            position: "absolute",
            bottom: "30px",
            left: "20%",
            transform: "translateX(-50%)",
          }}
        >
          Showing {searchPaperResults.length} results
        </div>
      )}
      {zoom > MIN_ZOOM_FOR_AREA_SEARCH && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
              position: "absolute",
              bottom: "30px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <AreaSearch />
            {false && isDebugMode && (
              <Button
                style={{
                  borderRadius: "20px",
                  padding: "7px 10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "3px",
                }}
                onClick={() => {
                  setPromptingSelection({
                    selectionType: "search",
                    selectionArgs: {
                      map_name: mapName,
                      q: searchQuery,
                      limit: 1000,
                      exact_matches: true,
                    },
                  });
                }}
              >
                Prompt Search Results
              </Button>
            )}
            {isDebugMode && (
              <CopySelectionButton
                selectionConfig={{
                  selection_type: "search",
                  map_name: mapName,
                  q: searchQuery,
                  limit: 1000,
                  exact_matches: true,
                }}
              />
            )}
            {isDebugMode && (
              <Button
                style={{
                  borderRadius: "20px",
                  padding: "7px 10px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "3px",
                }}
                onClick={() => {
                  setPromptingSelection({
                    selectionType: "bounds",
                    selectionArgs: {
                      map_name: mapName,
                      bounds: [
                        viewport.sw.lng,
                        viewport.sw.lat,
                        viewport.ne.lng,
                        viewport.ne.lat,
                      ],
                    },
                  });
                }}
              >
                Prompt Bounds
              </Button>
            )}
            {isDebugMode && (
              <CopySelectionButton
                selectionConfig={{
                  selection_type: "bounds",
                  map_name: mapName,
                  bounds: [
                    viewport.sw.lng,
                    viewport.sw.lat,
                    viewport.ne.lng,
                    viewport.ne.lat,
                  ],
                }}
              />
            )}
          </div>
        </div>
      )}
      {!searchPaperResults && isDebugMode && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "center",
            position: "absolute",
            bottom: "30px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button
            style={{
              borderRadius: "20px",
              padding: "7px 10px",
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
            onClick={() => {
              setPromptingSelection({
                selectionType: "bounds",
                selectionArgs: {
                  map_name: mapName,
                  bounds: [
                    viewport.sw.lng,
                    viewport.sw.lat,
                    viewport.ne.lng,
                    viewport.ne.lat,
                  ],
                },
              });
            }}
          >
            Prompt Bounds
          </Button>

          {isDebugMode && (
            <CopySelectionButton
              selectionConfig={{
                selection_type: "bounds",
                map_name: mapName,
                bounds: [
                  viewport.sw.lng,
                  viewport.sw.lat,
                  viewport.ne.lng,
                  viewport.ne.lat,
                ],
              }}
            />
          )}
        </div>
      )}
    </Map>
  );
};

export default MapReactMap;