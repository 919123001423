import "./HoverPopup.scss";
import { useContext, useMemo, useEffect, useState } from "react";
import MapContext, { IHoveredCluster } from "../../contexts/MapContext";
import AIAnswer from "../ai/AIAnswer";
import IPaperSelection from "../../interfaces/paperSelection";
import AIQuestionFunctionCallingButton from "../ai/AIQuestionFunctionCallingButton";
import KeywordsChipsRenderer from "../renderers/KeywordsChipRenderer";
import AIQuestionFunctionCallingInline from "../ai/AIQuestionFunctionCallingInline";
import TinyKeywordsChipsRenderer from "../renderers/TinyKeywordsChipRenderer";
import KeywordsList from "../renderers/KeywordsList";
import { fetchClusterDetails } from "../../requests";
import IClusterDetails from "../../interfaces/clusterDetails";
import ClusterHoverPictureDisplay from "./ClusterHoverPictureDisplay";

export default function ClusterHoverPopup({
  hoveredCluster,
  generateDescription = true,
}: {
  hoveredCluster: IHoveredCluster;
  generateDescription?: boolean;
}) {
  const { mapName } = useContext(MapContext);
  const [clusterDetails, setClusterDetails] = useState<IClusterDetails | null>(
    null
  );

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const details = await fetchClusterDetails(
          mapName,
          hoveredCluster.cluster_id
        );
        setClusterDetails(details);
      } catch (error) {
        console.error("Error loading cluster details:", error);
      }
    };
    loadDetails();
  }, [mapName, hoveredCluster.cluster_id]);

  // Memoize common contexts
  const defaultContexts = useMemo(
    () => ({
      method: "sampled_titles",
      sampling: { n: 50 },
      content: ["title"],
    }),
    []
  );

  const keywordsSelection = useMemo(
    () => ({
      selection_type: "cluster",
      map_name: mapName,
      cluster_id: hoveredCluster.cluster_id,
      limit: 200,
    }),
    [mapName, hoveredCluster.cluster_id]
  );

  const keywordsContexts = useMemo(
    () => ({
      method: "sampled_titles",
      sampling: { n: 100 },
      content: ["title"],
    }),
    []
  );

  // Memoize selection objects for each AIQuestionButton
  const descriptionSelection = useMemo(
    () => ({
      selection_type: "cluster",
      map_name: mapName,
      cluster_id: hoveredCluster.cluster_id,
      limit: 200,
    }),
    [mapName, hoveredCluster.cluster_id]
  );

  const keywordPromptingConfig = useMemo(
    () => ({
      function_name: "extract_keywords",
      function_description:
        "Extract a list of relevant keywords from the provided cluster papers (Maximum 10)",
      property_name: "keywords",
      function_calling_args: {
        keywords: {
          type: "array",
          items: {
            type: "string",
          },
          description: "Array of keywords",
        },
      },
      message_prompt_prefix:
        "Please extract the main keywords from the following content (Maximum 10):",
      required_keys: ["keywords"],
      system_prompt:
        "You are an AI assistant specialized in extracting keywords from academic papers.",
    }),
    []
  );

  return (
    <div className="popupHoverCluster" onClick={() => {}}>
       <ClusterHoverPictureDisplay
       autoGenerateDelay={1000}
        cluster_id={hoveredCluster.cluster_id}
        autoGenerate={true}
      />
      <div className="clusterContent">
        <div className="clusterHeader">Topic</div>
        <div className="clusterLabel">
          {hoveredCluster.label ?? "Not loaded yet"}
        </div>
        <div className="clusterDescription">
          {generateDescription && (
            <AIAnswer
              numSkeletonRows={1}
              allowRegenerate={false}
              autoGenerate={true}
              prompt={
                "Describe the main research question behind this cluster (represented by this selection of paper titles) in one short simple sentence that can be understood by a layperson. Start with 'How ...?' or 'What is ...?' or similar."
              }
              contexts={defaultContexts}
              selection={descriptionSelection as IPaperSelection}
              markdownStyles={{
                paragraphFontSize: "10px",
                paragraphColor: "#333333",
              }}
              autoGenerateDelay={1000}
              cachingKey={`${mapName}_cluster_${hoveredCluster.cluster_id}_research_question`}
            />
          )}
          <div style={{ marginTop: "6px" }}></div>
          <AIQuestionFunctionCallingInline
            autoGenerate={true}
            prompt="Provide a list of up to 10 keywords mentioned in the papers of this cluster, along with an indicator of their strength within the cluster."
            contexts={keywordsContexts}
            selection={keywordsSelection}
            promptingConfig={keywordPromptingConfig}
            renderer={KeywordsList}
            model="gpt-4o-mini"
            cachingKey={`${mapName}_cluster_${hoveredCluster.cluster_id}_keywords_fc`}
          />
        </div>

        <div className="hoverInfo">Click to view details</div>
      </div>
    </div>
  );
}
