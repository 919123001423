

export default function KeywordsList(keywords: string[]) {
  if (!Array.isArray(keywords)) {
    return <div>Invalid data format for keywords.</div>;
  }

  return (
    <div style={{
      height: "2.4em",
      lineHeight: "1.2em",
      overflow: "hidden",
      fontSize:"10px",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical"
    }}>
      <b>Keywords:</b>{' '}
      {keywords.join(', ')}
    </div>
  );
}