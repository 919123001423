// src/apiFunctions.ts
import API_ROUTES from "./ApiRoutes";
import IViewport from "./interfaces/viewport";
import IClusterDetails from "./interfaces/clusterDetails";
import ILandmarkedPapers from "./interfaces/landmarkedPapers";
import IClusterMeta, { IClusterMetaSmall } from "./interfaces/clusterMeta";
import _ from "lodash";
import IClusterSearchResultMeta from "./interfaces/clusterSearchResultMeta";
import IPaperDetailsWithCoordinates, { IPaperDetails } from "./interfaces/paperDetails";
import IAuthorDetails from "./interfaces/authorDetails";
import { ILayer } from "./interfaces/mapConfig";
import { ISourceConfig } from "./contexts/MapContext";
import { getWithAbort, postWithAbort } from "./apiUtils";
import IPaperMeta from "./interfaces/paperMeta";
import IPaperSelection from "./interfaces/paperSelection";
import axios from "axios";

/**
 * Fetch the map configuration.
 */
export async function fetchMapConfig(mapName: string, signal?: AbortSignal): Promise<any> {
  try {
    return await getWithAbort<any>(API_ROUTES.getMapConfig(mapName), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error; // Re-throw to ensure the caller is aware of the cancellation
    } else {
      console.error("Error fetching map config:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch map configuration.");
    }
  }
}

/**
 * Update the map source JSON.
 */
export async function updateMapSourceJSON(
  mapName: string,
  sourceJson: any,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(API_ROUTES.updateMapSourceJSON(mapName), sourceJson, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error updating map source JSON:", error);
      throw new Error(error.response?.data?.message || "Failed to update map source JSON.");
    }
  }
}

/**
 * Post a viewport bookmark.
 */
export async function postViewportBookmark(
  mapName: string,
  viewport: any,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(
      API_ROUTES.postViewportBookmark(),
      { mapName, viewport },
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error posting viewport bookmark:", error);
      throw new Error(error.response?.data?.message || "Failed to post viewport bookmark.");
    }
  }
}

/**
 * Get paper selection based on criteria.
 */
export async function getPaperSelection(
  mapName: string,
  selection: IPaperSelection,
  signal?: AbortSignal
): Promise<{ papers: IPaperMeta[]; cluster_metas_by_clusterId: Record<number, IClusterMetaSmall> }> {
  try {
    // Prepare parameters by converting arrays to comma-separated strings
    const params: Record<string, string> = {
      selection_type: selection.selection_type,
      map_name: selection.map_name,
    };

    if (selection.fields && selection.fields.length > 0) {
      params.fields = selection.fields.join(',');
    }

    if (selection.sampling) {
      params.sampling = JSON.stringify(selection.sampling);
    }

    if (selection.limit !== undefined && selection.limit !== null) {
      params.limit = selection.limit.toString();
    }

    if (selection.cluster_id !== undefined && selection.cluster_id !== null) {
      params.cluster_id = selection.cluster_id.toString();
    }

    if (selection.cluster_ids && selection.cluster_ids.length > 0) {
      params.cluster_ids = selection.cluster_ids.join(',');
    }

    if (selection.bounds && selection.bounds.length > 0) {
      params.bounds = selection.bounds.join(',');
    }

    if (selection.ids && selection.ids.length > 0) {
      params.ids = selection.ids.join(',');
    }
    if (selection.author_id) {
      params.author_id = selection.author_id.toString();
    }

    if (selection.q) {
      params.q = selection.q;
    }

    return await getWithAbort<{ papers: IPaperMeta[]; cluster_metas_by_clusterId: Record<number, IClusterMetaSmall> }>(
      API_ROUTES.getPaperSelection(mapName),
      {
        params,
        paramsSerializer: (params) => {
          const searchParams = new URLSearchParams();
          Object.keys(params).forEach((key) => {
            const value = params[key];
            if (value !== undefined && value !== null && value !== '') {
              searchParams.append(key, value);
            }
          });
          return searchParams.toString();
        },
      },
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper selection:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper selection.");
    }
  }
}

/**
 * Fetch details of a specific cluster.
 */
export async function fetchClusterDetails(
  mapName: string,
  clusterId: number,
  signal?: AbortSignal
): Promise<IClusterDetails> {
  try {
    const response = await getWithAbort<IClusterDetails>(API_ROUTES.fetchClusterDetails(mapName, clusterId), undefined, signal);

    if (typeof response !== "object") {
      throw new Error("Invalid response");
    }

    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching cluster details:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch cluster details.");
    }
  }
}

/**
 * Generate a picture for a specific cluster.
 */
export async function generateClusterPicture(
  mapName: string,
  clusterId: number,
  contextType: string,
  signal?: AbortSignal
): Promise<IClusterDetails> {
  try {
    return await postWithAbort<IClusterDetails>(
      API_ROUTES.postGenerateClusterPicture(mapName, clusterId),
      { context_type: contextType },
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error generating cluster picture:", error);
      throw new Error(error.response?.data?.message || "Failed to generate cluster picture.");
    }
  }
}

/**
 * Fetch papers in a viewport.
 */
export async function fetchPapersInViewport(
  mapName: string,
  viewport: IViewport,
  signal?: AbortSignal
): Promise<IPaperMeta[]> {
  try {
    const response = await getWithAbort<IPaperMeta[]>(API_ROUTES.getPapersInViewport(mapName, viewport), undefined, signal);
    if (!Array.isArray(response)) {
      throw new Error("Invalid response format");
    }
    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching papers in viewport:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch papers in viewport.");
    }
  }
}

/**
 * Fetch clusters in a viewport.
 */
export async function fetchClustersInViewport(
  mapName: string,
  viewport: IViewport,
  signal?: AbortSignal
): Promise<IClusterMeta[]> {
  try {
    const response = await getWithAbort<IClusterMeta[]>(API_ROUTES.getClustersInViewport(mapName, viewport), undefined, signal);
    if (!Array.isArray(response)) {
      throw new Error("Invalid response format");
    }
    return response;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching clusters in viewport:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch clusters in viewport.");
    }
  }
}

/**
 * Fetch search results.
 */
export async function fetchSearchResults(
  mapName: string,
  query: string,
  bounds: number[] | null = null,
  limit: number = 1000,
  signal?: AbortSignal
): Promise<{
  areas: any;
  landmarks: any;
  papers: IPaperMeta[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
  heatmap: {
    source: any;
    layer: any;
  };
}> {
  try {
    return await getWithAbort<{
      areas: any;
      landmarks: any;
      papers: IPaperMeta[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
      heatmap: {
        source: any;
        layer: any;
      };
    }>(
      API_ROUTES.searchPapers(mapName, query, limit, bounds),
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching search results:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch search results.");
    }
  }
}

/**
 * Fetch author details.
 */
export async function fetchAuthorDetails(
  authorId: number,
  signal?: AbortSignal
): Promise<IAuthorDetails> {
  try {
    return await getWithAbort<IAuthorDetails>(API_ROUTES.fetchAuthorDetails(authorId), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching author details:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch author details.");
    }
  }
}

/**
 * Fetch author papers.
 */
export async function fetchAuthorPapers(
  mapName: string,
  authorId: number,
  signal?: AbortSignal
): Promise<{
  layers: ILayer[];
  sources: ISourceConfig[];
  papers: IPaperMeta[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      layers: ILayer[];
      sources: ISourceConfig[];
      papers: IPaperMeta[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.fetchAuthorPapers(mapName, authorId), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching author papers:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch author papers.");
    }
  }
}

/**
 * Fetch author citations.
 */
export async function fetchAuthorCitations(
  mapName: string,
  authorId: number,
  signal?: AbortSignal
): Promise<{
  layers: ILayer[];
  sources: ISourceConfig[];
  papers: IPaperMeta[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      layers: ILayer[];
      sources: ISourceConfig[];
      papers: IPaperMeta[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.fetchAuthorCitations(mapName, authorId), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching author citations:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch author citations.");
    }
  }
}

/**
 * Fetch author references.
 */
export async function fetchAuthorReferences(
  mapName: string,
  authorId: number,
  signal?: AbortSignal
): Promise<{
  layers: ILayer[];
  sources: ISourceConfig[];
  papers: IPaperMeta[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      layers: ILayer[];
      sources: ISourceConfig[];
      papers: IPaperMeta[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.fetchAuthorReferences(mapName, authorId), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching author references:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch author references.");
    }
  }
}

/**
 * Create a context.
 */
export async function createContext(
  selection: any,
  contexts: any,
  signal?: AbortSignal
): Promise<string> {
  try {
    return await postWithAbort<string>(
      API_ROUTES.postCreateContext(),
      { selection, contexts },
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating context:", error);
      throw new Error(error.response?.data?.message || "Failed to create context.");
    }
  }
}

/**
 * Function calling with prompting.
 */
export async function promptFunctionCalling(
  selection: any,
  contexts: any,
  prompt: any,
  promptingConfig: any,
  model: string = "gpt-4o-mini",
  cachingKey: string | null = null,
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload: any = {
      selection,
      contexts,
      prompt,
      prompting_config: promptingConfig,
      model,
    };

    if (cachingKey) {
      payload.caching_key = cachingKey;
    }

    return await postWithAbort<any>(
      API_ROUTES.postFunctionCallingPrompting(),
      payload,
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error in function calling prompting:", error);
      throw new Error(error.response?.data?.message || "Failed to perform function calling prompting.");
    }
  }
}

/**
 * Old function calling prompting (if needed).
 */
export async function promptFunctionCalling_old(
  selection: any,
  contexts: any,
  prompting: any,
  model: string = "gpt-4o-mini",
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(
      API_ROUTES.postFunctionCallingPrompting_old(),
      { selection, contexts, prompting, model },
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error in old function calling prompting:", error);
      throw new Error(error.response?.data?.message || "Failed to perform old function calling prompting.");
    }
  }
}

/**
 * Fetch autocomplete suggestions.
 */
export async function fetchAutocompleteSuggestions(
  mapName: string,
  query: string,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await getWithAbort<any>(API_ROUTES.getAutocompleteSuggestions(mapName, query), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching autocomplete suggestions:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch autocomplete suggestions.");
    }
  }
}

/**
 * Create a heatmap layer.
 */
export async function createHeatmapLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateHeatmapLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating heatmap layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create heatmap layer.");
    }
  }
}

/**
 * Create a hexbin layer.
 */
export async function createHexbinLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateHexbinLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating hexbin layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create hexbin layer.");
    }
  }
}

/**
 * Create a grid layer.
 */
export async function createGridLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateGridLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating grid layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create grid layer.");
    }
  }
}

/**
 * Create a cluster label layer.
 */
export async function createClusterLabelLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateClusterLabelsLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating cluster label layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create cluster label layer.");
    }
  }
}

/**
 * Create a cluster layer.
 */
export async function createClusterLayer(
  selection: any,
  data_config: any,
  geometry_config: any,
  style_config: any,
  theme: "light" | "dark",
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload = { selection, data_config, geometry_config, style_config, theme };
    return await postWithAbort<any>(API_ROUTES.postCreateClusterLayer(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error creating cluster layer:", error);
      throw new Error(error.response?.data?.message || "Failed to create cluster layer.");
    }
  }
}

/**
 * Prompt full text with optional caching.
 */
export async function promptFullText(
  selection: any,
  contexts: any,
  prompt: any,
  model: string = "gpt-4o-mini",
  cachingKey: string | null = null,
  signal?: AbortSignal
): Promise<any> {
  try {
    const payload: any = {
      selection,
      contexts,
      prompt,
      model,
    };

    if (cachingKey) {
      payload.caching_key = cachingKey;
    }

    return await postWithAbort<any>(API_ROUTES.postFullTextPrompting(), payload, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error in full text prompting:", error);
      throw new Error(error.response?.data?.message || "Failed to perform full text prompting.");
    }
  }
}

/**
 * Get cached response.
 */
export async function getCachedResponse(
  cachingKey: string,
  signal?: AbortSignal
): Promise<any> {
  if (!cachingKey) throw new Error("cachingKey is required to get cached response");

  try {
    return await getWithAbort<any>(API_ROUTES.getCachedResponse(cachingKey), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      throw error;
    } else {
      throw new Error(error.response?.data?.message || "Failed to get cached response.");
    }
  }
}

/**
 * Fetch paper details with coordinates.
 */
export async function fetchPaperDetailsWithCoordinates(
  mapName: string,
  corpusid: number,
  signal?: AbortSignal
): Promise<IPaperDetailsWithCoordinates> {
  try {
    return await getWithAbort<IPaperDetailsWithCoordinates>(
      API_ROUTES.getPaperDetailsWithCoordinates(mapName, corpusid),
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper details with coordinates:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper details with coordinates.");
    }
  }
}

/**
 * Generate a paper picture.
 */
export async function generatePaperPicture(
  mapName: string,
  corpusid: number,
  signal?: AbortSignal
): Promise<any> {
  try {
    return await postWithAbort<any>(
      API_ROUTES.postGeneratePaperPicture(mapName, corpusid),
      {}, // Assuming no payload is needed; adjust if necessary
      undefined,
      signal
    );
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error generating paper picture:", error);
      throw new Error(error.response?.data?.message || "Failed to generate paper picture.");
    }
  }
}

/**
 * Fetch paper details.
 */
export async function fetchPaperDetails(
  corpusid: number,
  fields?: string[],
  signal?: AbortSignal
): Promise<IPaperDetails> {
  try {
    let url = API_ROUTES.getPaperDetails(corpusid);
    if (fields && fields.length > 0) {
      const params = new URLSearchParams();
      params.append("fields", fields.join(","));
      url += `?${params.toString()}`;
    }

    return await getWithAbort<IPaperDetails>(url, undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper details:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper details.");
    }
  }
}

/**
 * Fetch paper references.
 */
export async function fetchPaperReferences(
  mapName: string,
  corpusid: number,
  signal?: AbortSignal
): Promise<{
  papers: IPaperMeta[];
  sources: ISourceConfig[];
  layers: ILayer[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      papers: IPaperMeta[];
      sources: ISourceConfig[];
      layers: ILayer[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.getPaperReferences(mapName, corpusid), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper references:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper references.");
    }
  }
}

/**
 * Fetch paper citations.
 */
export async function fetchPaperCitations(
  mapName: string,
  corpusid: number,
  signal?: AbortSignal
): Promise<{
  papers: IPaperMeta[];
  sources: ISourceConfig[];
  layers: ILayer[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      papers: IPaperMeta[];
      sources: ISourceConfig[];
      layers: ILayer[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.getPaperCitations(mapName, corpusid), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper citations:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper citations.");
    }
  }
}

/**
 * Fetch paper nearest neighbors.
 */
export async function fetchPaperNearestNeighbors(
  mapName: string,
  corpusid: number,
  signal?: AbortSignal
): Promise<{
  papers: IPaperMeta[];
  sources: ISourceConfig[];
  layers: ILayer[];
  cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
}> {
  try {
    return await getWithAbort<{
      papers: IPaperMeta[];
      sources: ISourceConfig[];
      layers: ILayer[];
      cluster_metas_by_cluster_id: Record<number, IClusterMetaSmall>;
    }>(API_ROUTES.getPaperNearestNeighbors(mapName, corpusid), undefined, signal);
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled:", error.message);
      throw error;
    } else {
      console.error("Error fetching paper nearest neighbors:", error);
      throw new Error(error.response?.data?.message || "Failed to fetch paper nearest neighbors.");
    }
  }
}