// src/hooks/useViewport.ts

import { useEffect, useContext, useRef } from "react";
import { MapRef } from "react-map-gl/maplibre";
import MapContext from "../contexts/MapContext";

const useViewport = (mapRef: React.RefObject<MapRef>, setMapRef: any,setHasInteractionInViewport:any,hoverDelayTimeoutRef:any) => {
  const {
    setViewport,
    setZoom,
    targetCenter,
  } = useContext(MapContext);


  const handleMove = () => {
    if (mapRef.current) {
      const _map = mapRef.current.getMap();
      const bounds = _map.getBounds();

      setViewport({
        sw: {
          lng: bounds.getSouthWest().lng,
          lat: bounds.getSouthWest().lat,
        },
        ne: {
          lng: bounds.getNorthEast().lng,
          lat: bounds.getNorthEast().lat,
        },
        center: {
          lng: bounds.getCenter().lng,
          lat: bounds.getCenter().lat,
        },
        zoom: _map.getZoom(),
      });
      setZoom(_map.getZoom());

      // Reset interaction state as viewport has changed
      setHasInteractionInViewport(false);

      // Clear any existing hover delay timeout
      if (hoverDelayTimeoutRef.current) {
        clearTimeout(hoverDelayTimeoutRef.current);
        //make sure that the timeout is reset for popups, when the viewport changes (e.g. user is scrolling or panning)
        //NOTE: Google maps only resets when panning but not when scrolling
        hoverDelayTimeoutRef.current = null;
      }
   
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      setMapRef(mapRef.current);
    }
  }, [mapRef, setMapRef]);

  return { handleMove };
};

export default useViewport;