// src/components/panels/ClusterDetailsPanel.tsx

import { Button, CircularProgress, IconButton } from "@mui/material";
import { useContext, useEffect, useState, useMemo } from "react";
import MapContext from "../../contexts/MapContext";
import IClusterDetails from "../../interfaces/clusterDetails";
import { fetchClusterDetails } from "../../requests";
import PaperList from "../lists/PaperList";
import ClusterPictureDisplay from "../paper/ClusterPictureDisplay";
import "./ClusterDetailsPanel.scss";

import CodeIcon from "@mui/icons-material/Code";
import { Tab, Tabs, Skeleton } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "../../contexts/SnackbarContext";
import AIQuestionButton from "../ai/AIQuestionButton";
import ClusterPreviewBatch from "../clusters/ClusterPreviewBatch";
import ClusterPreviewBatchList from "../clusters/ClusterPreviewBatchList";
import ClusterAIButton from "../paper/ClusterAIButton";
import CopySelectionButton from "../utils/CopySelectionButton";
import CollapsibleSegment from "./CollapsibleSegment";
import LazyGenerateSection from "./LazyGenerateSection";

import AIQuestionFunctionCallingButton from "../ai/AIQuestionFunctionCallingButton";
import KeywordsChipsRenderer from "../renderers/KeywordsChipRenderer";

export default function ClusterDetailsPanel() {
  const [selectedTab, setSelectedTab] = useState(0);
  const snackbar = useSnackbar();
  const {
    selectedCluster,
    selectedClusterDetails,
    setSelectedClusterDetails,
    mapName,
    setSelectedCluster,
    setPromptingSelection,
  } = useContext(MapContext);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const isDebugMode = location.search.includes("debug");

  // Fetch cluster details when selectedCluster changes
  useEffect(() => {
    if (selectedCluster) {
      setIsLoading(true);
      fetchClusterDetails(mapName, selectedCluster.cluster_id)
        .then((details: IClusterDetails) => {
          setSelectedClusterDetails(details);
          //setSelectedCluster(details); // e.g., to update the geometry which is not available when clicking on the label
        })
        .catch((e) => {
          setSelectedClusterDetails(null);
          setSelectedCluster(null);
          snackbar.showSnackbar("Error fetching cluster details", "error");
        })
        .finally(() => setIsLoading(false));
    } else {
      setSelectedClusterDetails(null);
    }
  }, [
    selectedCluster?.cluster_id,
    mapName,
    snackbar,
    setSelectedCluster,
    setSelectedClusterDetails,
  ]);

  // Memoize common contexts
  const defaultContexts = useMemo(
    () => ({
      method: "sampled_titles",
      sampling: { n: 50 },
      content: ["title"],
    }),
    []
  );

  const detailsContexts = useMemo(
    () => ({
      method: "sampled_titles",
      sampling: { n: 200 },
      content: ["title", "abstract"],
    }),
    []
  );

  const keywordsContexts = useMemo(
    () => ({
      method: "sampled_titles",
      sampling: { n: 100 },
      content: ["title"],
    }),
    []
  );

  // Memoize selection objects for each AIQuestionButton
  const researchQuestionSelection = useMemo(
    () => ({
      selection_type: "cluster",
      map_name: mapName,
      cluster_id: selectedClusterDetails?.cluster_id,
      limit: 200,
    }),
    [mapName, selectedClusterDetails?.cluster_id]
  );

  const descriptionSelection = useMemo(
    () => ({
      selection_type: "cluster",
      map_name: mapName,
      cluster_id: selectedClusterDetails?.cluster_id,
      limit: 200,
    }),
    [mapName, selectedClusterDetails?.cluster_id]
  );

  const keywordsSelection = useMemo(
    () => ({
      selection_type: "cluster",
      map_name: mapName,
      cluster_id: selectedClusterDetails?.cluster_id,
      limit: 200,
    }),
    [mapName, selectedClusterDetails?.cluster_id]
  );

  const applicationsSelection = useMemo(
    () => ({
      selection_type: "cluster",
      map_name: mapName,
      cluster_id: selectedClusterDetails?.cluster_id,
      limit: 200,
    }),
    [mapName, selectedClusterDetails?.cluster_id]
  );

  const keywordPromptingConfig = useMemo(
    () => ({
      function_name: "extract_keywords",
      function_description:
        "Extract a list of relevant keywords from the provided cluster papers (Maximum 10)",
      property_name: "keywords",
      function_calling_args: {
        keywords: {
          type: "array",
          items: {
            type: "string",
          },
          description: "Array of keywords",
        },
      },
      message_prompt_prefix:
        "Please extract the main keywords from the following content (Maximum 10):",
      required_keys: ["keywords"],
      system_prompt:
        "You are an AI assistant specialized in extracting keywords from academic papers.",
    }),
    []
  );

  return (
    <div className="clusterPanel">
      <div></div>
      <div className="clusterContainer">
        {!isLoading && selectedClusterDetails && (
          <>
            {selectedTab === 0 && (
              <>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginTop: "0px",
                    marginBottom: "5px",
                  }}
                >
                  <ClusterPictureDisplay
                    clusterDetails={selectedClusterDetails}
                    autoGenerate={true}
                  />
                </div>

                <div className="clusterHeader" style={{ textAlign: "left" }}>
                  {selectedClusterDetails.label.toUpperCase()}
                  {isDebugMode && !selectedClusterDetails.image_url && (
                    <ClusterAIButton
                      clusterId={selectedClusterDetails.cluster_id}
                      mapName={mapName}
                    />
                  )}
                  {isDebugMode && (
                    <IconButton
                      onClick={() => {
                        setPromptingSelection({
                          selectionType: "cluster",
                          selectionArgs: {
                            map_name: mapName,
                            cluster_id: selectedClusterDetails.cluster_id,
                          },
                        });
                      }}
                    >
                      <CodeIcon />
                    </IconButton>
                  )}
                  {isDebugMode && (
                    <CopySelectionButton
                      selectionConfig={{
                        selection_type: "cluster",
                        map_name: mapName,
                        cluster_id: selectedClusterDetails.cluster_id,
                      }}
                    />
                  )}
                </div>
              </>
            )}
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              style={{ paddingBottom: "10px" }}
            >
              <Tab label="Overview" />
              <Tab label="Papers" />
              {isDebugMode && <Tab label="Words" />}
              {false && <Tab label="Pictures" />}
            </Tabs>
            {selectedTab === 0 && (
              <>
                <div className="cluster-segment-container">
                  <div className="cluster-segment">
                    {selectedClusterDetails.short_description && (
                      <CollapsibleSegment name={"Short Description"}>
                        {selectedClusterDetails.short_description}
                      </CollapsibleSegment>
                    )}
                  </div>
                  {/* Research Question Segment */}
                  <CollapsibleSegment
                    name="Research Question"
                    maxPreviewHeight={null}
                  >
                    <AIQuestionButton
                      autoGenerate={true}
                      question={"What is this field of research about?"}
                      prompt={
                        "Describe the main research question behind this cluster (represented by this selection of paper titles) in one short simple sentence that can be understood by a layperson. Start with 'How ...?' or 'What is ...?' or similar."
                      }
                      contexts={defaultContexts}
                      selection={researchQuestionSelection}
                      cachingKey={`${mapName}_cluster_${selectedClusterDetails.cluster_id}_research_question`}
                    />
                  </CollapsibleSegment>

                  {/* Keywords Segment */}
                  <CollapsibleSegment
                    name="Top Keywords"
                    maxPreviewHeight={400}
                  >
                    <AIQuestionFunctionCallingButton
                      autoGenerate={true}
                      question="What are the main keywords of this field?"
                      prompt="Provide a list of up to 10 keywords mentioned in the papers of this cluster, along with an indicator of their strength within the cluster."
                      contexts={keywordsContexts}
                      selection={keywordsSelection}
                      promptingConfig={keywordPromptingConfig}
                      renderer={KeywordsChipsRenderer}
                      model="gpt-4o-mini"
                      cachingKey={`${mapName}_cluster_${selectedClusterDetails.cluster_id}_keywords_fc`}
                    />
                  </CollapsibleSegment>

                  {/* Description Segment */}
                  <CollapsibleSegment name="Description">
                    <AIQuestionButton
                      autoGenerate={true}
                      question={"What is this field of research about?"}
                      prompt={
                        "Describe this cluster (represented by this selection of paper titles) in up to 4 simple bulletpoints with one sentence each. Refer to this cluster as 'this research field' or similar. Use simple language that can be understood by a layperson."
                      }
                      contexts={defaultContexts}
                      selection={descriptionSelection}
                      cachingKey={`${mapName}_cluster_${selectedClusterDetails.cluster_id}_description`}
                    />
                  </CollapsibleSegment>

                  {isDebugMode && (
                    <CollapsibleSegment name="Findings">
                      <AIQuestionButton
                        autoGenerate={false}
                        question={
                          "What are the top research findings of this field?"
                        }
                        prompt={
                          "Give a list of most essential research findings in this field (represented by the selection of papers with their titles and abstracts). Return as a bulletpoint list."
                        }
                        contexts={detailsContexts}
                        selection={keywordsSelection}
                        cachingKey={`${mapName}_cluster_${selectedClusterDetails.cluster_id}_findings`}
                      />
                    </CollapsibleSegment>
                  )}
                  {/* Applications Segment */}
                  <CollapsibleSegment name="Applications">
                    <AIQuestionButton
                      autoGenerate={true}
                      question="What are applications for this field of research?"
                      prompt={
                        "How does this field of research (represented by the given selection of paper titles) apply to real-world problems? Return a short list of up to 4 elements. Use simple language that can be understood by a layperson."
                      }
                      contexts={defaultContexts}
                      selection={applicationsSelection}
                      cachingKey={`${mapName}_cluster_${selectedClusterDetails.cluster_id}_applications`}
                    />
                  </CollapsibleSegment>

                  {/* Child Topics Segment */}
                  {selectedClusterDetails.child_clusters.length > 0 && (
                    <CollapsibleSegment name="Child Topics">
                      <div>
                        <ClusterPreviewBatchList
                          relation="child"
                          clusterMetas={selectedClusterDetails.child_clusters}
                        />
                      </div>
                    </CollapsibleSegment>
                  )}

                  {/* Parent Topic Segment */}
                  {selectedClusterDetails.parent_cluster && (
                    <CollapsibleSegment
                      name="Parent Topic"
                      maxPreviewHeight={null}
                    >
                      <ClusterPreviewBatch
                        relation="parent"
                        clusterMeta={selectedClusterDetails.parent_cluster}
                      />
                    </CollapsibleSegment>
                  )}

                  {/* Most Cited Papers Segment */}
                  {selectedClusterDetails.selections_by_type["most_cited"] && (
                    <CollapsibleSegment
                      name="Most Cited Papers"
                      maxPreviewHeight={null}
                    >
                      <PaperList
                        selection={
                          selectedClusterDetails.selections_by_type[
                            "most_cited"
                          ]
                        }
                        sortBy="citationcount"
                        sortDirection="desc"
                        limit={10}
                        type={"cluster"}
                      />
                    </CollapsibleSegment>
                  )}
                </div>
              </>
            )}

            {/* Papers Tab Content */}
            {selectedTab === 1 && (
              <PaperList
                selection={
                  selectedClusterDetails.selections_by_type["most_cited"]
                }
                type={"cluster"}
                sortBy="citationcount"
                customSortingOptions={[
                  { field: "citationcount", label: "Citations" },
                  { field: "year", label: "Year" },
                ]}
              />
            )}
          </>
        )}

        {/* Loading Indicator */}
        {!selectedClusterDetails && (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress size={20} />
          </div>
        )}
      </div>

      {/* Close Button */}
      <div
        style={{
          padding: "10px",
          borderTop: "1px solid #eee",
          backgroundColor: "#f9f9f9",
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            setSelectedCluster(null);
            setSelectedClusterDetails(null);
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
