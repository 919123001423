// src/utils/searchUtils.ts

import { LayerProps } from "react-map-gl";
import { createLandmarkLayerConfig, createLandmarkLayerProps } from "./createLandmarkLayer";
import { fetchSearchResults } from "./requests";
import { ISourceConfig } from "./contexts/MapContext";
import IPaperMeta from "./interfaces/paperMeta";

export async function performSearch(
  mapName: string,
  searchQuery: string,
  viewport: any,
  zoom: number,
  setSearchPaperResults: (results: any) => void,
  setSearchClusterResults: (results: any) => void,
  upsertLayer: (id: string, layer: any, source: ISourceConfig, beforeId?: string) => void,
  navigate: (path: string) => void,
  snackbar: any,
  fitToBounds: (options: {
    points?: { lng: number; lat: number }[];
    bounds?: { ne: { lng: number; lat: number }; sw: { lng: number; lat: number } };
  }, buffer?: number, animated?: boolean) => void,
  bounds?: number[] | null,
  shouldFitToBounds: boolean = true
) {
  try {
    const searchFeatures = await fetchSearchResults(mapName, searchQuery, bounds, 1000);
    let layerConfigWithoutTitles: LayerProps = createLandmarkLayerProps(
      createLandmarkLayerConfig(searchFeatures.landmarks),
      true,
      true
    );
    layerConfigWithoutTitles.maxzoom = 8;
    layerConfigWithoutTitles.id = "search_landmarks_without_titles";
    layerConfigWithoutTitles.source = "search_landmarks_without_titles";

    let layerConfigWithTitles: LayerProps = createLandmarkLayerProps(
      createLandmarkLayerConfig(searchFeatures.landmarks),
      false,
      true
    );
    layerConfigWithTitles.minzoom = 8;
    layerConfigWithTitles.layout!["icon-ignore-placement"] = false;
    layerConfigWithTitles.id = "search_landmarks_with_titles";
    layerConfigWithTitles.source = "search_landmarks_with_titles";

    const sourceWithTitles: ISourceConfig = {
      data: searchFeatures.landmarks.data,
      type: "geojson",
    };
    const sourceWithoutTitles: ISourceConfig = {
      data: searchFeatures.landmarks.data,
      type: "geojson",
    };

    upsertLayer(layerConfigWithTitles.id, layerConfigWithTitles as any, sourceWithTitles, "cluster_3_labels");
    upsertLayer(layerConfigWithoutTitles.id, layerConfigWithoutTitles as any, sourceWithoutTitles, "cluster_3_labels");
    upsertLayer("search_heatmap", searchFeatures.heatmap.layer, searchFeatures.heatmap.source,"papers");

    setSearchPaperResults(searchFeatures.papers);
    setSearchClusterResults(searchFeatures.cluster_metas_by_cluster_id);

    const centerLng = ((viewport.ne.lng + viewport.sw.lng) / 2).toFixed(7);
    const centerLat = ((viewport.ne.lat + viewport.sw.lat) / 2).toFixed(7);

    const papers=searchFeatures.papers;
    //filter to papers which have geometry-> throw warning if geometry is missing
    const papersWithGeometry=papers.filter(paper=>paper.geometry);
    if (papersWithGeometry.length<papers.length){
      console.warn("Some papers do not have geometry");
    }
    if (shouldFitToBounds) {
      const coordinates=papersWithGeometry.map((paper:IPaperMeta)=>({lng: paper.geometry!.coordinates[0] as number,lat: paper.geometry!.coordinates[1] as number}));
      fitToBounds({points: coordinates},0.1,true);
    }
    //navigate(newPath);
  } catch (e: any) {
    if (e.response?.status === 404) {
      snackbar.showSnackbar("No papers found", "info");
    } else {
      snackbar.showSnackbar("Error searching", "error");
      console.error(e);
    }
  }
}
