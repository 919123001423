// src/components/MapPopups.tsx

import React from "react";
import { Popup } from "react-map-gl/maplibre";
import ClusterHoverPopup from "./popup/ClusterHoverPopup";
import PaperHoverPopup from "./popup/PaperHoverPopup";

interface MapPopupsProps {
  hoveredPaper: any;
  hoveredCluster: any;
}

const MapPopups: React.FC<MapPopupsProps> = ({ hoveredPaper, hoveredCluster }) => (
  <>
    {hoveredPaper?.geometry && hoveredPaper.source === "map" && (
        //@ts-ignore
      <Popup
        longitude={hoveredPaper.geometry.coordinates[0] as number}
        latitude={hoveredPaper.geometry.coordinates[1] as number}
        closeButton={false}
        //@ts-ignore
        offset={[0, -20]}
        className="hovered-paper-popup"
      >
        <PaperHoverPopup hoveredPaper={hoveredPaper} />
      </Popup>
    )}
    {hoveredCluster?.geometry && hoveredCluster.source === "label" && (
        //@ts-ignore
      <Popup
        longitude={hoveredCluster.geometry.coordinates[0] as number}
        latitude={hoveredCluster.geometry.coordinates[1] as number}
        closeButton={false}
        //@ts-ignore
        offset={[0, -20]}
        className="hovered-cluster-popup"
      >
        <ClusterHoverPopup hoveredCluster={hoveredCluster} />
      </Popup>
    )}
  </>
);

export default MapPopups;