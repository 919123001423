// src/components/MapHandler.tsx

import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Button, FormControlLabel, IconButton, Switch } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import LayersIcon from "@mui/icons-material/Layers";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import { MapProvider } from "react-map-gl/maplibre";
import _, { zip } from "lodash";
import MapContext, { ISourceConfig } from "../contexts/MapContext";
import MapReactMap from "./MapReactMap";
import SearchResultsPanel from "./SearchResultsPanel";
import SideBar from "./SideBar";
import SidebarToggleButton from "./SidebarToggleButton";
import ClusterDetailsPanel from "./panels/ClusterDetailsPanel";
import LayerControl from "./panels/LayerControl";
import PaperDetailsPanel from "./panels/PaperDetailsPanel";
import PromptingPanel from "./panels/PromptingPanel";
import LayerCreationPanel from "./panels/LayerCreationPanel";
import LoadingScreen from "./utils/LoadingScreen";
import { useSearch } from "../hooks/useSearch";
import { ILayer, IMapConfig } from "../interfaces/mapConfig";
import IViewport from "../interfaces/viewport";
import { fetchAuthorDetails, fetchMapConfig } from "../requests";
import { SearchButton } from "./SearchButton";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useSnackbar } from "../contexts/SnackbarContext";
import AuthorDetailsPanel from "./panels/AuthorDetailsPanel";
import ColorLegend from "./ColorLegend";

function MapHandler() {
  const { name, coordinates, query, authorId, corpusId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [mapConfig, setMapConfig] = useState<IMapConfig | null>(null);
  const [showLayerControls, setShowLayerControls] = useState(false);
  const [showCreateLayerPanel, setShowCreateLayerPanel] = useState(false);
  const isDebugMode = location.search.includes('debug');

  const {
    darkmode,
    selectedAuthorId,
    setDarkmode,
    upsertLayer,
    mapName,
    setMapName,
    addLayer,
    removeLayer,
    searchQuery,
    setSearchQuery,
    setSearchClusterResults,
    setViewport,
    setTargetCenter,
    viewport,
    setSourceJSON,
    sourceJSON,
    zoom,
    setZoom,
    selectedCorpusId,
    selectedCluster,
    setSelectedCluster,
    searchPaperResults,
    sidebarOpen,
    setSearchPaperResults,
    setSelectedCorpusId,
    setSelectedAuthorId,
    setSidebarOpen,
    promptingSelection,
    setPromptingSelection,
    zoomIn,
    flyToAnimated,
    zoomOut,
  } = useContext(MapContext);

  const snackbar = useSnackbar();
  const { loading, search } = useSearch();

  // **New State for Search Trigger**
  const [searchTrigger, setSearchTrigger] = useState(0); // Added

  const handleShowAll = () => {
    // Reset to default view
    setZoom(2);
    setViewport({
      ne: { lat: 85, lng: 180 },
      sw: { lat: -85, lng: -180 },
      center: { lat: 0, lng: 0 },
      zoom: 2
    });
  };

  useEffect(() => {
    if (name) {
      setMapName(name);
    }
    if (query) {
      setSearchQuery(query);
    }
    if (authorId) {
      setSelectedAuthorId(parseInt(authorId));
    }
    if (corpusId) {
      setSelectedCorpusId(parseInt(corpusId));
    }
  
    if (coordinates) {
      const [latStr, lngStr, zoomStr] = coordinates.split(",");
      const parsedLat = parseFloat(latStr);
      const parsedLng = parseFloat(lngStr);
      const parsedZoom = parseFloat(zoomStr.replace("z", ""));
  
      setTargetCenter({
        lat: parsedLat,
        lng: parsedLng,
        zoom: parsedZoom,
      });
  
      //setZoom(parsedZoom);
  
      // **Use flyToAnimated instead of manually setting viewport**
      /*flyToAnimated({
        center: { lng: parsedLng, lat: parsedLat },
        zoom: parsedZoom,
        ne: { lng: parsedLng + 0.01, lat: parsedLat + 0.01 }, // Optional: You can remove these if not needed
        sw: { lng: parsedLng - 0.01, lat: parsedLat - 0.01 }, // Optional: You can remove these if not needed
      });*/
    }
  }, [name, query, authorId, corpusId, coordinates]);

  useEffect(() => {
    if (mapName) {
      fetchMapConfig(mapName)
        .then((mapConfig: IMapConfig) => {
          const sortedLayers = mapConfig.source_json.layers;
          const _sourceJson = {
            ...mapConfig.source_json,
            layers: sortedLayers,
          };

          setMapConfig({
            ...mapConfig,
            source_json: _sourceJson,
          });
          setSourceJSON(_.cloneDeep(_sourceJson));
        })
        .catch(() => {
          alert("Map not found");
        });
    }
  }, [mapName]);

  useEffect(() => {
    if (mapConfig) {
      if (darkmode && mapConfig.source_json_darkmode) {
        setSourceJSON(mapConfig.source_json_darkmode);
      } else {
        setSourceJSON(mapConfig.source_json);
      }
    }
  }, [darkmode, mapConfig]);

  useEffect(() => {
    let newUrl = getNewUrl(
      mapName,
      viewport,
      zoom,
      searchQuery,
      selectedAuthorId,
      selectedCorpusId
    );
    if (isDebugMode) {
      newUrl += '?debug';
    }
    window.history.replaceState({}, "", newUrl);
  }, [mapName, viewport, zoom, searchQuery, selectedAuthorId, selectedCorpusId]);

  // **Modified useEffect to Include searchTrigger**
  //TODO make only initially
  /*useEffect(() => {
    if (searchQuery) {
      console.debug("searchQuery", searchQuery);
      search(searchQuery).then(() => {
        setSidebarOpen(true);
      });
    } else {
      // setHighlightLayers([]); TODO
    }
  }, [searchQuery, searchTrigger]); // Added searchTrigger*/

  function handleSearchSubmit(q: string) {
    setSearchQuery(q);
    search(q,null,true).then(() => {
      setSidebarOpen(true);
    });
    //setSearchTrigger(prev => prev + 1); // Increment trigger in order to be able to re-trigger for the same query
  }

  function handleClearSearch() {
    setSearchClusterResults(null);
    setSearchPaperResults(null);
    removeLayer("search_landmarks_with_titles");
    removeLayer("search_landmarks_without_titles");
    removeLayer("search_heatmap");
    setSearchQuery("");
    const newUrl = getNewUrl(mapName, viewport, zoom);
    if (isDebugMode) {
      window.history.replaceState({}, "", newUrl + '?debug');
    } else {
      window.history.replaceState({}, "", newUrl);
    }

    setSidebarOpen(false);
  }

  const handleToggleDarkMode = () => {};

  if (!sourceJSON || !mapConfig) return <LoadingScreen />;
  return (
    <>
      <div style={{ display: "flex", zIndex: 1 }}>
        <SearchButton
          initialValue={query || ""}
          onSubmit={handleSearchSubmit}
          onClear={handleClearSearch}
          loading={loading}
        />

        {sidebarOpen && (
          <SideBar>
            <SearchResultsPanel />
          </SideBar>
        )}
        {!!promptingSelection && (
          <PromptingPanel
            selectionType={promptingSelection.selectionType}
            selectionArgs={promptingSelection.selectionArgs}
          />
        )}
        <div style={{ height: "100%" }}>
          <MapProvider>
            <MapReactMap sourceJSON={sourceJSON} config={mapConfig} />

            {isDebugMode && mapConfig?.source_json_darkmode && (
              <div
                style={{
                  textAlign: "center",
                  height: "50px",
                  position: "absolute",
                  top: "10px",
                  right: "100px",
                }}
              >
                <FormControlLabel
                  style={{ padding: 0, margin: 0 }}
                  control={
                    <Switch
                      checked={darkmode}
                      onChange={() => {
                        setDarkmode(!darkmode);
                      }}
                      color="default"
                      icon={<LightModeIcon />}
                      checkedIcon={<DarkModeIcon />}
                    />
                  }
                  label={darkmode ? "Dark Mode" : "Light Mode"}
                  labelPlacement="start"
                />
              </div>
            )}
            {isDebugMode && showCreateLayerPanel && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                }}
              >
                <LayerCreationPanel
                  onLayerCreated={(layers, sources) => {
                    const zipped = zip(layers, sources);
                    for (const [layer, source] of zipped) {
                      addLayer(layer as ILayer, source as ISourceConfig);
                    }
                  }}
                />
              </div>
            )}
            {isDebugMode && showLayerControls && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                }}
              >
                <LayerControl
                  sourceJson={sourceJSON}
                  onLayersUpdate={(layers: ILayer[]) => {
                    setSourceJSON({
                      ...sourceJSON,
                      layers: layers,
                    });
                  }}
                />
              </div>
            )}
            {isDebugMode && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                {
                  <IconButton
                    onClick={() => setShowLayerControls(!showLayerControls)}
                  >
                    <LayersIcon style={{ fontSize: "30px" }} />
                  </IconButton>
                }

                {
                  <IconButton
                    onClick={() => setShowCreateLayerPanel(!showCreateLayerPanel)}
                  >
                    <AddIcon style={{ fontSize: "30px" }} />
                  </IconButton>
                }
              </div>
            )}

            {/* Zoom Controls */}
            <div
              style={{
                position: "absolute",
                right: "15px",
                bottom: "30px",
                borderRadius: "3px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginBottom: "8px" }}>
                <IconButton 
                  onClick={()=> flyToAnimated({center: {lng: 0, lat: 0}, zoom: 2, ne: {lng: 180, lat: 85}, sw: {lng: -180, lat: -85}})} 
                  size="small" 
                  style={{
                    padding: "4px",
                    borderRadius: "8px",
                    backgroundColor: "white",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.2)"
                  }}
                >
                  <CenterFocusStrongIcon style={{fontSize: "22px"}} />
                </IconButton>
              </div>
              <div style={{
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column"
              }}>
                <IconButton onClick={() => zoomIn()} size="small" style={{padding: "4px"}}>
                  <AddIcon style={{fontSize: "22px"}} />
                </IconButton>
                <div style={{
                  height: "1px",
                  backgroundColor: "#e0e0e0",
                  margin: "0 4px"
                }} />
                <IconButton onClick={() => zoomOut()} size="small" style={{padding: "4px"}}>
                  <RemoveIcon style={{fontSize: "22px"}}/>
                </IconButton>
              </div>
              {/* Add the ColorLegend component */}
            </div>
            <div style={{position: "absolute", bottom: 30, right: 57}}>
              <ColorLegend />
            </div>

          </MapProvider>
        </div>
        {searchPaperResults &&
          !selectedCorpusId &&
          !selectedCluster &&
          !selectedAuthorId && <SidebarToggleButton />}
        {isDebugMode && (
          <div
            style={{
              position: "absolute",
              zIndex: 2000,
              bottom: 10,
              right: 50,
              padding: 30,
            }}
          >
            Zoom: {zoom.toFixed(2)}
          </div>
        )}
        <div
          style={{
            position: "absolute",
            zIndex: 2000,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            fontSize: "11px",
            borderRadius: "3px",
            padding: "2px 5px",
            "lineHeight": "13px"
          }}
        >
          Copyright © Scholarmaps 2024 &nbsp;•&nbsp; 
          Data by SemanticScholar &nbsp;•&nbsp;
          <a href="/impressum" style={{color: "#333"}}>Impressum</a> &nbsp;•&nbsp;
          <a href="/about" style={{color: "#333"}}>About</a>
        </div>
      </div>
      {selectedCluster && <ClusterDetailsPanel />}
      {selectedAuthorId && <AuthorDetailsPanel />}
      {selectedCorpusId && <PaperDetailsPanel />}
    </>
  );
}

export default MapHandler;

function getNewUrl(
  mapName: string,
  viewport: IViewport,
  zoom: number,
  searchQuery?: string | null,
  selectedAuthorId?: number | null,
  selectedCorpusId?: number | null
): string {
  const centerLng = ((viewport.ne.lng + viewport.sw.lng) / 2).toFixed(7);
  const centerLat = ((viewport.ne.lat + viewport.sw.lat) / 2).toFixed(7);
  let url = `/map/${mapName}`;

  if (searchQuery) {
    url += `/search/${encodeURIComponent(searchQuery)}`;
  }

  if (selectedAuthorId) {
    url += `/author/${selectedAuthorId}`;
  }

  if (selectedCorpusId) {
    url += `/corpus/${selectedCorpusId}`;
  }

  url += `/coordinates/${centerLat},${centerLng},${zoom}z`;

  return url;
}