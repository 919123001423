import React, { useContext, useState, KeyboardEvent, useEffect, useRef } from "react";
import { Box, TextField, IconButton, CircularProgress, Typography, Stack } from "@mui/material";
import { useSearch } from "../hooks/useSearch";
import MapContext from "../contexts/MapContext";
import SearchIcon from '@mui/icons-material/Search';

interface AreaSearchProps {}

const AreaSearch: React.FC<AreaSearchProps> = () => {
  const { viewport, searchQuery, setSearchQuery,setSidebarOpen } = useContext(MapContext);
  const [areaSearchQuery, setAreaSearchQuery] = useState("");
  const [showInput, setShowInput] = useState(true);

  const { loading, search,setLoading } = useSearch();

  // Ref to track if the searchQuery update is internal
  const isInternalUpdate = useRef(false);

  useEffect(() => {

    if (isInternalUpdate.current) {
      // Reset the flag and do not set showInput
      isInternalUpdate.current = false;
    } else {
      if (searchQuery) {
        setAreaSearchQuery(searchQuery);
        setShowInput(false);
      } else {
        setShowInput(true);
      }
    }
  }, [searchQuery]);

  const handleSearchClick = () => {
    if (areaSearchQuery.trim().length > 0) {
      setShowInput(true);

      search(
        areaSearchQuery,
        [
          viewport.sw.lng,
          viewport.sw.lat,
          viewport.ne.lng,
          viewport.ne.lat,
        ],
        false
      ).then(() => {
        setSidebarOpen(true);
      });
      // Indicate that the update is internal
      isInternalUpdate.current = true;
      setSearchQuery(areaSearchQuery);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && areaSearchQuery.trim().length > 0) {
      handleSearchClick();
    }
  };

  const handleAreaQueryClick = () => {
    setShowInput(true);
  };

  return (
    <Box 
      display="flex" 
      alignItems="center"
      sx={{
        backgroundColor: '#fff',
        borderRadius: '24px',
        boxShadow: '0 2px 6px rgba(0,0,0,0.15)',
        padding: '8px 16px',
        minHeight: '40px',
        minWidth: '250px'
      }}
    >
      <IconButton 
        onClick={handleSearchClick} 
        disabled={loading || areaSearchQuery.trim().length === 0}
        sx={{ 
          padding: '8px',
          marginRight: '4px',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' }
        }}
      >
        {loading ? (
          <CircularProgress size={22} /> 
        ) : (
          <SearchIcon sx={{ fontSize: 22, color: areaSearchQuery.trim().length === 0 ? '#BDBDBD' : '#5B5B5B' }} />
        )}
      </IconButton>

      <Stack spacing={0} width="100%">
        {showInput ? (
          <TextField
            variant="standard"
            placeholder="Search in this area"
            value={areaSearchQuery}
            onChange={(e) => setAreaSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              '& input': {
                padding: '4px 0',
                fontSize: '15px',
                fontWeight: 500
              }
            }}
            fullWidth
          />
        ) : (
          <Typography
            variant="body1"
            onClick={handleAreaQueryClick}
            sx={{
              fontSize: '15px',
              fontWeight: 500,
              color: '#1a73e8',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            "{areaSearchQuery}"
          </Typography>
        )}
        {!showInput && (
          <Typography
            variant="caption"
            sx={{
              color: '#70757a',
              fontSize: '12px',
              marginTop: '-2px'
            }}
          >
            Search within visible region
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default AreaSearch;