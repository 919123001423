import {
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LinkIcon from "@mui/icons-material/Link";
import CodeIcon from "@mui/icons-material/Code";

import {
  fetchPaperCitations,
  fetchPaperDetailsWithCoordinates,
  fetchPaperNearestNeighbors,
  fetchPaperReferences,
} from "../../requests";
import { zip } from "lodash";

import MapContext, {
  HoveredPaperType,
  IHighlightLayer,
} from "../../contexts/MapContext";
import { useSnackbar } from "../../contexts/SnackbarContext";

import ILandmarkedPapers from "../../interfaces/landmarkedPapers";
import IPaperDetailsWithCoordinates from "../../interfaces/paperDetails";
import IPaperMeta from "../../interfaces/paperMeta";

import PaperList from "../lists/PaperList";
import AuthorsDisplay from "../paper/AuthorsDisplay";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import PaperAIButton from "../paper/PaperAIButton";
import YearDisplay from "../paper/YearDisplay";
import CopySelectionButton from "../utils/CopySelectionButton";
import CollapsibleSegment from "./CollapsibleSegment";

import { useLocation } from "react-router-dom";
import { IClusterMetaSmall } from "../../interfaces/clusterMeta";

export default function PaperDetailsPanel() {
  const {
    selectedCorpusId,
    setSelectedAuthorDetails,
    setSelectedAuthorId,
    selectedPaperDetails,
    setSelectedPaperDetails,
    setSelectedCorpusId,
    removePaperLayers,
    setPromptingSelection,
    upsertLayer,
    viewport,
    flyTo,
    removeLayer,
    fitToBounds,
    mapName,
  } = useContext(MapContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPapers, setIsLoadingPapers] = useState(false);
  const [papers, setPapers] = useState<IPaperMeta[]>([]);
  const [clusterMetasByClusterId, setClusterMetasByClusterId] = useState<Record<number,IClusterMetaSmall>|null>(null);
  const [nnLandmarks, setNNLandmarks] = useState<ILandmarkedPapers | null>(
    null
  );

  const isMounted = useRef(true);
  const [currentTab, setCurrentTab] = useState(0); // 0: Details, 1: Citations, 2: References, 3: Similar
  const snackbar = useSnackbar();
  const location = useLocation();
  const isDebugMode = location.search.includes("debug");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  // Cleanup effect to set the ref to false when the component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Reset the ref when the component re-mounts
  useEffect(() => {
    isMounted.current = true;
  }, [selectedCorpusId]);

  // Fetch paper details when selectedCorpusId changes
  useEffect(() => {
    if (selectedCorpusId) {
      setSelectedAuthorId(null);
      setSelectedAuthorDetails(null);

      setCurrentTab(0); // Reset to Details tab
      setNNLandmarks(null);
      setIsLoading(true);

      fetchPaperDetailsWithCoordinates(mapName, selectedCorpusId)
        .then((details: IPaperDetailsWithCoordinates) => {
          if (!isMounted.current) return;
          setSelectedPaperDetails(details);

          const currentZoom = viewport.zoom;
          const targetZoom = currentZoom > 12 ? currentZoom : 12;

          flyTo(
            {
              center: {
                lng: details.geometry.coordinates[0] as number,
                lat: details.geometry.coordinates[1] as number,
              },
              zoom: targetZoom,
              ne: {
                lng: details.geometry.coordinates[0] as number,
                lat: details.geometry.coordinates[1] as number,
              },
              sw: {
                lng: details.geometry.coordinates[0] as number,
                lat: details.geometry.coordinates[1] as number,
              },
            },
            true
          );
        })
        .catch((error: any) => {
          if (isMounted.current) {
            snackbar.showSnackbar("Error fetching paper details", "error");
            setSelectedCorpusId(null); // Hide the panel
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setSelectedPaperDetails(null);
    }
  }, [selectedCorpusId]);

  // Fetch papers based on the selected tab
  useEffect(() => {
    if (!selectedCorpusId) {
      return;
    }

    // Only fetch data if the current tab is not "Details"
    if (currentTab === 0) {
      removePaperLayers();
      // Zoom to selected paper when on Details tab
      if (selectedPaperDetails) {
        const currentZoom = viewport.zoom;
        const targetZoom = currentZoom > 12 ? currentZoom : 12;

        flyTo(
          {
            center: {
              lng: selectedPaperDetails.geometry.coordinates[0] as number,
              lat: selectedPaperDetails.geometry.coordinates[1] as number,
            },
            zoom: targetZoom,
            ne: {
              lng: selectedPaperDetails.geometry.coordinates[0] as number,
              lat: selectedPaperDetails.geometry.coordinates[1] as number,
            },
            sw: {
              lng: selectedPaperDetails.geometry.coordinates[0] as number,
              lat: selectedPaperDetails.geometry.coordinates[1] as number,
            },
          },
          true
        );
      }
      return;
    }

    setIsLoadingPapers(true);
    setPapers([]);
    setClusterMetasByClusterId(null);

    const fetchData = async () => {
      try {
        let response;
        if (currentTab === 1) {
          // Citations
          response = await fetchPaperCitations(mapName, selectedCorpusId);
        } else if (currentTab === 2) {
          // References
          response = await fetchPaperReferences(mapName, selectedCorpusId);
        } else if (currentTab === 3) {
          // Similar
          response = await fetchPaperNearestNeighbors(
            mapName,
            selectedCorpusId
          );
        }

        if (!response) {
          return;
        }

        if (!isMounted.current) return;

        removePaperLayers();
        setPapers(response.papers);
        setClusterMetasByClusterId(response.cluster_metas_by_cluster_id);

        const layers = response.layers;
        const sources = response.sources;
        const zipped = zip(layers, sources);
        for (const [layer, source] of zipped) {
          if (layer && source) {
            upsertLayer(layer.id, layer, source, "paper_labels");
          }
        }

        // Fit bounds to all papers when on Citations or References tab
        if (
          (currentTab === 1 || currentTab === 2) &&
          response.papers.length > 0
        ) {
          const coordinates = response.papers
            .filter((paper) => paper.geometry)
            .map((paper) => ({
              lng: paper.geometry!.coordinates[0] as number,
              lat: paper.geometry!.coordinates[1] as number,
            }));
          if (coordinates.length > 0) {
            fitToBounds({ points: coordinates }, 0.1, true);
          }
        }
      } catch (error: any) {
        if (isMounted.current) {
          const errorMessage =
            currentTab === 1
              ? "Error fetching citations"
              : currentTab === 2
              ? "Error fetching references"
              : "Error fetching similar papers";
          snackbar.showSnackbar(errorMessage, "error");
          if (currentTab === 3) {
            setCurrentTab(0); // Reset to Details tab on error for Similar tab
          }
        }
      } finally {
        if (isMounted.current) setIsLoadingPapers(false);
      }
    };

    fetchData();
  }, [currentTab]);

  const getCount = () => {
    if ((currentTab === 1 || currentTab === 2) && papers.length > 0) {
      return `(${papers.length})`;
    }
    return "";
  };

  const getHoverType = (): HoveredPaperType => {
    if (currentTab === 1) {
      return "paper_citation";
    } else if (currentTab === 2) {
      return "paper_reference";
    } else if (currentTab === 3) {
      return "paper_similar";
    } else {
      throw new Error("Invalid tab");
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        zIndex: 4,
        boxShadow: "1px 1px 4px #c5c5c5",
        backgroundColor: "white",
      }}
    >
      {selectedPaperDetails ? (
        <>
          {/* Tab Content */}
          <div style={{ flex: 1, overflowY: "auto" }}>
            {currentTab === 0 && (
              <div style={{ padding: "20px", paddingTop: 0,paddingBottom:0 }}>
                <h3 style={{ textAlign: "left", marginBottom: "-11px" }}>
                  {selectedPaperDetails.title}{" "}
                  {isDebugMode && (
                    <PaperAIButton
                      selectedCorpusId={selectedPaperDetails.id}
                      mapName={mapName}
                    />
                  )}
                  {isDebugMode && (
                    <Button
                      onClick={() => {
                        setPromptingSelection({
                          selectionType: "custom",
                          selectionArgs: {
                            map_name: mapName,
                            ids: [selectedPaperDetails.id],
                          },
                        });
                      }}
                    >
                      <CodeIcon />
                    </Button>
                  )}
                  {isDebugMode && (
                    <CopySelectionButton
                      selectionConfig={{
                        selection_type: "custom",
                        map_name: mapName,
                        ids: [selectedPaperDetails.id],
                      }}
                    />
                  )}
                </h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {selectedPaperDetails.url && (
                    <IconButton
                      sx={{ paddingLeft: 0 }}
                      href={selectedPaperDetails.url}
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                    >
                      <Tooltip title="Open in Semanticscholar" arrow>
                        <LinkIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  )}
                  {selectedPaperDetails.openaccesspdf?.url && (
                    <IconButton
                      href={selectedPaperDetails.openaccesspdf.url}
                      target="_blank"
                      rel="noreferrer"
                      size="small"
                    >
                      <Tooltip title="Open as PDF" arrow>
                        <PictureAsPdfIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  )}
                </div>
                <div
                  className="meta"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <YearDisplay year={selectedPaperDetails.year} />
                  <AuthorsDisplay authors={selectedPaperDetails.authors} />
                  <CitationCountDisplay
                    citationCount={selectedPaperDetails.citationcount}
                  />
                </div>
                <Divider style={{ marginTop: "10px" }} />
              </div>
            )}
            {/* Tabs */}
            <div style={{ padding: "0px 20px" }}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Overview" />
                <Tab
                  label={`Citations`}
                  disabled={selectedPaperDetails.citationcount === 0}
                />
                <Tab
                  label={`References`}
                />
                {false && (
                  <Tab
                    label={`Similar`}
                  />
                )}
              </Tabs>
            </div>
            {currentTab === 0 && selectedPaperDetails.tldr && (
              <div style={{ padding: "20px" }}>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                >
                  <b>TLDR:</b> {selectedPaperDetails.tldr}
                </div>

                <div style={{ marginTop: "10px" }}>
                  <CollapsibleSegment name={"Abstract"} maxPreviewHeight={200}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      {selectedPaperDetails.abstract ? (
                        selectedPaperDetails.abstract
                      ) : (
                        <i>No Abstract Available</i>
                      )}
                    </p>
                  </CollapsibleSegment>
                </div>
              </div>
            )}
            {(currentTab === 1 || currentTab === 2 || currentTab === 3) && (
              <div style={{ padding: "0px 20px" }}>
                {isLoadingPapers ? (
                  <PaperList
                    papers={[]}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                    showDummyLoading={true}
                    clusterMetas={clusterMetasByClusterId}
                  />
                ) : papers.length > 0 ? (
                  <PaperList
                    papers={papers}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                    clusterMetas={clusterMetasByClusterId}
                  />
                ) : (
                  <i>No Papers Found</i>
                )}
              </div>
            )}
          </div>

          {/* Close Button */}
          <div
            style={{
              padding: "10px",
              borderTop: "1px solid #eee",
              backgroundColor: "#f9f9f9",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                setSelectedCorpusId(null);
                setSelectedPaperDetails(null);
                removePaperLayers();
              }}
            >
              Close
            </Button>
          </div>
        </>
      ) : (
        // Loading Indicator when no paper is selected
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
}
