import {
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useRef, useState, useMemo } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LinkIcon from "@mui/icons-material/Link";
import CodeIcon from "@mui/icons-material/Code";

import {
  fetchAuthorCitations,
  fetchAuthorDetails,
  fetchAuthorPapers,
  fetchAuthorReferences,
} from "../../requests";
import { curryRight, zip } from "lodash";

import MapContext, { HoveredPaperType } from "../../contexts/MapContext";
import { useSnackbar } from "../../contexts/SnackbarContext";

import IPaperMeta from "../../interfaces/paperMeta";
import PaperList from "../lists/PaperList";
import CitationCountDisplay from "../paper/CitationCountDisplay";
import CollapsibleSegment from "./CollapsibleSegment";
import AIQuestionButton from "../ai/AIQuestionButton";
import AIQuestionFunctionCallingButton from "../ai/AIQuestionFunctionCallingButton";
import KeywordsChipsRenderer from "../renderers/KeywordsChipRenderer";

import { useLocation } from "react-router-dom";
import CopySelectionButton from "../utils/CopySelectionButton";
import { IClusterMetaSmall } from "../../interfaces/clusterMeta";

export default function AuthorDetailsPanel() {
  const {
    selectedAuthorId,
    selectedAuthorDetails,
    setSelectedAuthorId,
    setSelectedAuthorDetails,
    upsertLayer,
    removeAuthorLayers,
    mapName,
    fitToBounds,
    flyTo,
    viewport,
  } = useContext(MapContext);

  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingPapers, setIsLoadingPapers] = useState(false);
  const [papers, setPapers] = useState<IPaperMeta[]>([]);
  const [clusterMetasByClusterId, setClusterMetasByClusterId] = useState<null | Record<number, IClusterMetaSmall>>(null);

  const isMounted = useRef(true);
  const [currentTab, setCurrentTab] = useState(0); // 0: Author Papers, 1: Citations, 2: References
  const snackbar = useSnackbar();
  const location = useLocation();
  const isDebugMode = location.search.includes("debug");
  
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  // Cleanup effect to set the ref to false when the component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Reset the ref when the component re-mounts
  useEffect(() => {
    isMounted.current = true;
  }, [selectedAuthorId]);

  // Fetch author details when selectedAuthorId changes
  useEffect(() => {
    if (selectedAuthorId) {
      setIsLoadingDetails(true);
      fetchAuthorDetails(selectedAuthorId)
        .then((authorDetails) => {
          if (isMounted.current) {
            setSelectedAuthorDetails(authorDetails);
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            console.error(error);
            snackbar.showSnackbar("Error fetching author details", "error");
            setSelectedAuthorId(null); // Hide the panel
          }
        })
        .finally(() => {
          if (isMounted.current) setIsLoadingDetails(false);
        });
    } else {
      setSelectedAuthorDetails(null);
      removeAuthorLayers();
    }
  }, [selectedAuthorId, mapName]);

  // Fetch papers based on the selected tab
  useEffect(() => {
    if (!selectedAuthorId) {
      return;
    }

    // Only fetch data if the current tab is not "Author Papers" (if you want to always fetch Author Papers, adjust accordingly)
    // In this case, "Author Papers" is the default tab, so we'll fetch papers for it.

    const fetchData = async () => {
      setIsLoadingPapers(true);
      setPapers([]);

      try {
        let response;
        if (currentTab === 0) {
          // Author Papers
          response = await fetchAuthorPapers(mapName, selectedAuthorId);
        } else if (currentTab === 1) {
          // Citations
          response = await fetchAuthorCitations(mapName, selectedAuthorId);
        } else if (currentTab === 2) {
          // References
          response = await fetchAuthorReferences(mapName, selectedAuthorId);
        }

        if (!response) {
          return;
        }

        if (!isMounted.current) return;

        removeAuthorLayers();
        setPapers(response.papers);
        if (response.cluster_metas_by_cluster_id) {
          setClusterMetasByClusterId(response.cluster_metas_by_cluster_id);
        }

        const layers = response.layers;
        const sources = response.sources;
        const zipped = zip(layers, sources);
        for (const [layer, source] of zipped) {
          if (layer && source) {
            let layerType = "";
            if (currentTab === 0) {
              layerType = "author_papers";
            } else if (currentTab === 1) {
              layerType = "author_citations";
            } else if (currentTab === 2) {
              layerType = "author_references";
            }
            let beforeId="paper_labels"
            upsertLayer(layer.id, layer, source, beforeId);
          }
        }

        // Fit bounds to all papers when on Author Papers or Citations or References tab
        if (response.papers.length > 0) {
          const papersWithGeometry = response.papers.filter(
            (paper) => paper.geometry
          );
          const coordinates = papersWithGeometry.map((paper) => ({
            lng: paper.geometry!.coordinates[0] as number,
            lat: paper.geometry!.coordinates[1] as number,
          }));
          if (coordinates.length > 0) {
            fitToBounds({ points: coordinates }, 0.1, true);
          }
        }
      } catch (error: any) {
        if (isMounted.current) {
          const errorMessage =
            currentTab === 0
              ? "Error fetching author papers"
              : currentTab === 1
              ? "Error fetching author citations"
              : "Error fetching author references";
          console.error(error);
          snackbar.showSnackbar(errorMessage, "error");
          if (currentTab === 2) {
            setCurrentTab(0); // Reset to Author Papers tab on error for References tab
          }
        }
      } finally {
        if (isMounted.current) setIsLoadingPapers(false);
      }
    };

    fetchData();
  }, [currentTab, selectedAuthorId]);

  const getTabCount = () => {
    if (
      (currentTab === 0 || currentTab === 1 || currentTab === 2) &&
      papers.length > 0
    ) {
      return `(${papers.length})`;
    }
    return "";
  };

  const getHoverType = (): HoveredPaperType => {
    if (currentTab === 0) {
      return "author_paper";
    } else if (currentTab === 1) {
      return "author_citation";
    } else if (currentTab === 2) {
      return "author_reference";
    } else {
      throw new Error("Invalid tab");
    }
  };

  // Memoize selection and contexts for AIQuestionButton
  const researchQuestionSelection = useMemo(
    () => ({
      selection_type: "author_papers",
      map_name: mapName,
      author_id: selectedAuthorId,
      limit: 200,
    }),
    [mapName, selectedAuthorId]
  );

  const researchQuestionContexts = useMemo(
    () => ({
      method: "sampled_titles",
      sampling: { n: 200 },
      meta: ["citationcount", "year"],
      content: ["title"],
    }),
    []
  );

  const keywordPromptingConfig = useMemo(
    () => ({
      function_name: "extract_keywords",
      function_description:
        "Extract a list of relevant keywords from the provided papers (Maximum 10)",
      property_name: "keywords",
      function_calling_args: {
        keywords: {
          type: "array",
          items: {
            type: "string",
          },
          description: "Array of keywords",
        },
      },
      message_prompt_prefix:
        "Please extract the main keywords from the following content (Maximum 10):",
      required_keys: ["keywords"],
      system_prompt:
        "You are an AI assistant specialized in extracting keywords from academic papers.",
    }),
    []
  );

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "400px",
        zIndex: 4,
        boxShadow: "1px 1px 4px #c5c5c5",
        backgroundColor: "white",
      }}
    >
      {selectedAuthorDetails ? (
        <>
          {/* Author Details Section */}
          <div style={{ flex: 1, overflowY: "auto" }}>
            {currentTab === 0 && (
              <img
                src="/researcher.webp"
                alt="Author"
                style={{
                  maxHeight: "250px",
                  width: "400px",
                  display: "block",
                  margin: "auto",
                  objectFit: "cover",
                }}
              />
            )}

            <div style={{ padding: "20px", paddingTop: 0 }}>
              {currentTab === 0 && (
                <>
                  <h3 style={{ textAlign: "left", marginBottom: "5px" }}>
                    {selectedAuthorDetails.name}{" "}
                    {isDebugMode && (
                      <AIQuestionFunctionCallingButton
                        autoGenerate={true}
                        question="What are the main keywords of this author's research?"
                        prompt="Provide a list of up to 10 keywords mentioned in the papers of this author, along with an indicator of their strength within the cluster."
                        contexts={researchQuestionContexts}
                        selection={researchQuestionSelection}
                        cachingKey={
                          selectedAuthorId
                            ? `${mapName}_author_${selectedAuthorId}_keywords_fc`
                            : undefined
                        }
                        promptingConfig={keywordPromptingConfig}
                        renderer={KeywordsChipsRenderer}
                        model="gpt-4o-mini"
                      />
                    )}
                    {isDebugMode && (
                      <Button
                        onClick={() => {
                          // Define the prompting selection logic here
                          // Example:
                          // setPromptingSelection({
                          //   selectionType: "author",
                          //   selectionArgs: { author_id: selectedAuthorId },
                          // });
                        }}
                      >
                        <CodeIcon />
                      </Button>
                    )}
                    {isDebugMode && (
                      <CopySelectionButton
                        selectionConfig={{
                          selection_type: "author",
                          map_name: mapName,
                          author_id: selectedAuthorId,
                        }}
                      />
                    )}
                  </h3>
                  {selectedAuthorDetails.homepage && (
                    <div style={{ marginBottom: "10px" }}>
                      <a
                        href={selectedAuthorDetails.homepage}
                        style={{ textAlign: "left", fontSize: "11px" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Homepage
                      </a>
                    </div>
                  )}
                  <div
                    className="meta"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <CitationCountDisplay
                      citationCount={selectedAuthorDetails.citationCount}
                    />
                  </div>
                  <Divider style={{ marginTop: "10px" }} />
                  <div>
                    <CollapsibleSegment
                      name="Author Description"
                      maxPreviewHeight={150}
                    >
                      <AIQuestionButton
                        autoGenerate={true}
                        question={"What is this author researching about?"}
                        prompt={
                          "Describe the main research question(s) for the provided papers from this author. Give a brief overview of what this author is researching about. Highlight highly cited papers (if any) and their recent research (if relevant). Use simple language that can be understood by a layperson. Use one simple introductory sentence which describes the overall research field followed by up to 5 bullet points with one short sentence each. If there are too few papers, use fewer bullet points. Refer to this author as 'this researcher' or similar."
                        }
                        contexts={researchQuestionContexts}
                        selection={researchQuestionSelection}
                        cachingKey={
                          selectedAuthorId
                            ? `${mapName}_author_${selectedAuthorId}_research_question`
                            : undefined
                        }
                      />
                    </CollapsibleSegment>

                    {/* Keywords Segment */}
                    <CollapsibleSegment
                      name="Top Keywords"
                      maxPreviewHeight={400}
                    >
                      <AIQuestionFunctionCallingButton
                        autoGenerate={true}
                        question="What are the main keywords of this author's research?"
                        prompt="Provide a list of up to 10 keywords mentioned in the papers of this author, along with an indicator of their strength within the cluster."
                        contexts={researchQuestionContexts}
                        selection={researchQuestionSelection}
                        cachingKey={
                          selectedAuthorId
                            ? `${mapName}_author_${selectedAuthorId}_keywords_fc`
                            : undefined
                        }
                        promptingConfig={keywordPromptingConfig}
                        renderer={KeywordsChipsRenderer}
                        model="gpt-4o-mini"
                      />
                    </CollapsibleSegment>
                  </div>
                </>
              )}

              {/* Tabs */}
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                style={{ marginTop: "20px" }}
              >
                <Tab label={`Author Papers`} />
                <Tab
                  label={`Citations`}
                />
                <Tab
                  label={`References`}
                />
              </Tabs>

              <Divider />

              {/* Tab Content */}
              <div style={{ marginTop: "10px" }}>
                {isLoadingPapers ? (
                  <PaperList
                    papers={[]}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                    clusterMetas={clusterMetasByClusterId}
                    showDummyLoading={true}
                  />
                ) : papers.length > 0 ? (
                  <PaperList
                    papers={papers}
                    type={getHoverType()}
                    sortBy="year"
                    sortDirection="desc"
                    customSortingOptions={[
                      { field: "year", label: "Year" },
                      { field: "citationcount", label: "Citations" },
                    ]}
                    clusterMetas={clusterMetasByClusterId}
                  />
                ) : (
                  <i>No Papers Found</i>
                )}
              </div>

              <Divider />
            </div>
          </div>

          {/* Close Button */}
          <div
            style={{
              padding: "10px",
              borderTop: "1px solid #eee",
              backgroundColor: "#f9f9f9",
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                setSelectedAuthorId(null);
                setSelectedAuthorDetails(null);
                removeAuthorLayers();
              }}
            >
              Close
            </Button>
          </div>
        </>
      ) : (
        // Loading Indicator when no author is selected
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
}
